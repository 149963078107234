import React from "react";
import {
  List,
  Datagrid,
  Edit,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  Toolbar,
  SaveButton,
  Filter,
  TextInput
} from "react-admin";
import UserButtons from "./UserButtons";
import NumberInput from "admin-on-rest/lib/mui/input/NumberInput";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn/>
    <TextInput source="code" label="User code" alwaysOn/>
    <TextInput source="email" alwaysOn/>
  </Filter>
);

export const UserList = props => (
  <List {...props} filters={<UserFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="code"/>
      <TextField source="email"/>
      <TextField source="phone"/>
      <TextField source="country"/>
      <TextField source="presenter_code"/>
      <TextField source="point"/>
      <TextField source="count_insurance_referral" label="Insurance Referral"/>
      <TextField source="active"/>
      <TextField source="level"/>
      <TextField source="level_english"/>
      <TextField source="total_price"/>
      <TextField source="total_group_revenue"/>
      <TextField source="total_commission"/>
      <TextField source="total_revenue_this_month"/>
      <TextField source="total_revenue_previous_month"/>
      <TextField source="total_group_revenue_this_month"/>
      <TextField source="total_group_revenue_previous_month"/>
      <TextField source="audio_license_expire"/>
      <TextField source="is_active_license_kidz" label={"Active license Kidz"}/>
      <EditButton basePath="/users"/>
    </Datagrid>
  </List>
);

const UserTitle = ({record}) => {
  return <span>User {record ? `"${record.email}"` : ""}</span>;
};

const PostEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton/>
  </Toolbar>
);

export const UserEdit = props => (
  <Edit title={<UserTitle/>} {...props}>
    <SimpleForm toolbar={<PostEditToolbar/>}>
      <DisabledInput source="id"/>
      <DisabledInput source="code"/>
      <TextInput source="name"/>
      <TextInput source="email"/>
      <TextInput source="phone"/>
      <DisabledInput source="country"/>
      <TextInput source="total_price" label="Wallet"/>
      <DisabledInput source="active"/>
      <DisabledInput source="avatar"/>
      <TextInput source="bank_account"/>
      <TextInput source="bank_name"/>
      <TextInput source="bank_full_name" label="Full name (for bank)"/>
      <TextInput source="paypal_name" label="PayPal Account Name"/>
      <TextInput source="paypal_email" label="PayPal Account Email"/>
      <DisabledInput source="description"/>
      <DisabledInput source="presenter_code"/>
      <DisabledInput source="presenter_name"/>
      <DisabledInput source="max_price" label="Max course"/>
      <DisabledInput source="rank"/>
      <TextInput source="point"/>
      <DisabledInput source="count_insurance_referral" label="Insurance Referral"/>
      <DisabledInput source="total_group_revenue_previous_month" label="Group revenue last month"/>
      <DisabledInput source="total_group_revenue_this_month" label="Group revenue this month"/>
      <DisabledInput source="total_revenue_previous_month" label="Revenue last month"/>
      <DisabledInput source="total_revenue_this_month" label="Revenue this month"/>
      <DisabledInput source="audio_license_expire"/>
      <DisabledInput source="created_at"/>
      <DisabledInput source="updated_at"/>
      <UserButtons/>
    </SimpleForm>
  </Edit>
);
