import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  ImageField, SelectInput
} from "react-admin";
import EventWeekInput from "./EventWeekCustomInput";

const EventWeekFilter = (props) => (
  <Filter {...props}>
    <TextInput source="title" alwaysOn/>
  </Filter>
);

export const EventWeekList = props => (
  <List {...props} filters={<EventWeekFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="title"/>
      <TextField source="start"/>
      <TextField source="end"/>
      <TextField source="is_active"/>
      <EditButton basePath="/event-week"/>
    </Datagrid>
  </List>
);

export const EventWeekEdit = props => (
  <Edit {...props}>
    <EventWeekInput/>
  </Edit>
);

export const EventWeekCreate = props => (
  <Create {...props}>
    <EventWeekInput/>
  </Create>
);
