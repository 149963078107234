import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter
} from "react-admin";
import PromotionHistoryButton from "./PromotionHistoryButton";

const PromotionHistoryFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" label="User name" alwaysOn/>
    <TextInput source="code" label="User code" alwaysOn/>
    <TextInput source="title" label="Promotion title" alwaysOn/>
  </Filter>
);

export const PromotionHistoryList = props => (
  <List {...props} filters={<PromotionHistoryFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="user_code"/>
      <TextField source="user_name"/>
      <TextField source="user_tel" label="User phone"/>
      <TextField source="user_email"/>
      <TextField source="user_address"/>
      <TextField source="promotion_title"/>
      <TextField source="promotion_point"/>
	  <TextField source="redeem" label="Amount"/>
      <EditButton basePath="/promotion-history"/>
    </Datagrid>
  </List>
);

export const PromotionHistoryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <DisabledInput source="user_code"/>
      <DisabledInput source="user_name"/>
      <DisabledInput source="user_tel" label="User phone"/>
      <DisabledInput source="user_email"/>
      <DisabledInput source="user_address"/>
      <DisabledInput source="promotion_title"/>
      <DisabledInput source="promotion_point"/>
      <DisabledInput source="redeem" label="Amount"/>
      <DisabledInput source="status"/>
      <DisabledInput source="note" label="Deny reason"/>
      <PromotionHistoryButton/>
    </SimpleForm>
  </Edit>
);
