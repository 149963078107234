import React from "react";
import { Admin, Resource, MenuItemLink, DashboardMenuItem } from "react-admin";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CourseList, CourseEdit, CourseCreate } from "./Course";
import { PartList, PartEdit, PartCreate } from "./Part";
import { AttachmentList, AttachmentEdit, AttachmentCreate } from "./Attachment";
import { LessonList, LessonEdit, LessonCreate } from "./Lesson";
import {
  ChildCourseList,
  ChildCourseEdit,
  ChildCourseCreate
} from "./ChildCourse";
import { OrderList, OrderEdit } from "./Order";
import { UserList, UserEdit } from "./User";
import Dashboard from "./Dashboard";
import { authProvider } from "./authProvider";
import customProvider from "./customProvider";

import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PeopleIcon from "@material-ui/icons/People";
import Album from "@material-ui/icons/Album";
import Settings from "@material-ui/icons/Settings";
import LocalLibrary from "@material-ui/icons/LocalLibrary";
import CollectionsBookmark from "@material-ui/icons/CollectionsBookmark";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import Receipt from "@material-ui/icons/Receipt";
import Redeem from "@material-ui/icons/Redeem";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import FindReplace from "@material-ui/icons/FindReplace";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import VideoLibrary from "@material-ui/icons/VideoLibrary";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Audiotrack from "@material-ui/icons/Audiotrack";

import {NotificationCreate, NotificationEdit, NotificationList} from "./Notification";
import {RatioCreate, RatioEdit, RatioList} from "./Ratio";
import {SettingCreate, SettingEdit, SettingList} from "./Setting";
import {EventEdit, EventList} from "./Event";
import {TestCreate, TestEdit, TestList} from "./Test";
import {QuestionCreate, QuestionEdit, QuestionList} from "./Question";
import {ContractEdit, ContractList} from "./Contract";
import {PromotionCreate, PromotionEdit, PromotionList} from "./Promotion";
import {PromotionHistoryEdit, PromotionHistoryList} from "./PromotionHistory";
import {SpecialOptionCreate, SpecialOptionEdit, SpecialOptionList} from "./SpecialOption";
import {TransferRequestEdit, TransferRequestList} from "./TransferRequest";
import {InsuranceEdit, InsuranceList} from "./Insurance";
import {RefundHistoryEdit, RefundHistoryList} from "./RefundHistory";
import {CourseProgressEdit, CourseProgressList} from "./CourseProgress";
import {CourseEnglishList, CourseEnglishCreate, CourseEnglishEdit} from "./EnglishCourse";
import {OrderEnglishList, OrderEnglishEdit} from "./OrderEnglish";
import {InsuranceEnglishEdit, InsuranceEnglishList} from "./InsuranceEnglish";
import {CommissionHistoryEdit, CommissionHistoryList} from "./CommissionHistory";
import {RefundHistoryEnglishEdit, RefundHistoryEnglishList} from "./RefundHistoryEnglish";
import {RollingItemCreate, RollingItemEdit, RollingItemList} from "./RollingItem";
import {RollingResultEdit, RollingResultList} from "./RollingResult";
import {CourseProgressEnglishList, CourseProgressEnglishEdit} from "./CourseProgressEnglish";
import {PresenterList, PresenterEdit, PresenterCreate} from "./Presenter";
import {ResourceCreate, ResourceEdit, ResourceList} from "./Resource";
import {CommissionHistorySkillList, CommissionHistorySkillEdit} from "./CommissionHistorySkill";
import {OpinionList, OpinionCreate, OpinionEdit} from "./Opinion";
import {EventWeekCreate, EventWeekEdit, EventWeekList} from "./EventWeek";
import {AudioCreate, AudioEdit, AudioList} from "./Audio";
import {AudioBookCreate, AudioBookEdit, AudioBookList} from "./AudioBook";
import {CommissionHistoryAudioLicenseEdit, CommissionHistoryAudioLicenseList} from "./CommissionHistoryAudioLicense";
import {AudioBookCategoryCreate, AudioBookCategoryEdit, AudioBookCategoryList} from "./AudioBookCategory";
import {ActiveCodeCreate, ActiveCodeEdit, ActiveCodeList} from "./ActiveCode";
import {IntroduceHistoryEdit, IntroduceHistoryList} from "./IntroduceHistory";
import {OptimaKidzBookCreate, OptimaKidzBookEdit, OptimaKidzBookList} from "./OptimaKidzBook";
import {OptimaKidzCategoryCreate, OptimaKidzCategoryEdit, OptimaKidzCategoryList} from "./OptimaKidzCategory";
import {OptimaKidzFileCreate, OptimaKidzFileEdit, OptimaKidzFileList} from "./OptimaKidzFile";

function App() {
  return (

    <React.Fragment>
      <ToastContainer theme={"dark"} />
      <Admin
          dataProvider={customProvider}
          authProvider={authProvider}
          dashboard={Dashboard}
      >
        <Resource
            name="level-course"
            list={CourseList}
            edit={CourseEdit}
            create={CourseCreate}
            icon={LocalLibrary}
            options={{label: "Level Courses"}}
        />
        <Resource
            name="list-course"
            list={ChildCourseList}
            edit={ChildCourseEdit}
            create={ChildCourseCreate}
            icon={LocalLibrary}
            options={{label: "List Courses"}}
        />
        <Resource
            name="part-course"
            list={PartList}
            edit={PartEdit}
            create={PartCreate}
            icon={CollectionsBookmark}
            options={{label: "Part Courses"}}
        />
        <Resource
            name="lesson-course"
            list={LessonList}
            edit={LessonEdit}
            create={LessonCreate}
            icon={CollectionsBookmark}
            options={{label: "Lesson Courses"}}
        />
        {/*<Resource*/}
        {/*    name="attachments"*/}
        {/*    list={AttachmentList}*/}
        {/*    edit={AttachmentEdit}*/}
        {/*    create={AttachmentCreate}*/}
        {/*    icon={CollectionsBookmark}*/}
        {/*/>*/}
        <Resource
            name="orders"
            list={OrderList}
            edit={OrderEdit}
            icon={AttachMoneyIcon}
        />
        <Resource
            name="courses-english-order"
            list={OrderEnglishList}
            edit={OrderEnglishEdit}
            icon={AttachMoneyIcon}
            options={{label: "Orders (Course English)"}}
        />
        <Resource
            name="commission-history-english"
            list={CommissionHistoryList}
            edit={CommissionHistoryEdit}
            icon={AttachMoneyIcon}
            options={{label: "Commission History English"}}
        />
        <Resource
            name="commission-history-skill"
            list={CommissionHistorySkillList}
            edit={CommissionHistorySkillEdit}
            icon={AttachMoneyIcon}
            options={{label: "Commission History Skill"}}
        />
        <Resource
            name="commission-history-audio-license"
            list={CommissionHistoryAudioLicenseList}
            edit={CommissionHistoryAudioLicenseEdit}
            icon={AttachMoneyIcon}
            options={{label: "Commission History Audio License"}}
        />
        <Resource
            name="commission-history-affiliate"
            list={IntroduceHistoryList}
            edit={IntroduceHistoryEdit}
            icon={AttachMoneyIcon}
            options={{label: "Introduce History"}}
        />
        <Resource
            name="users"
            list={UserList}
            edit={UserEdit}
            icon={PeopleIcon}
        />
        <Resource
            name="audio-book-category"
            list={AudioBookCategoryList}
            edit={AudioBookCategoryEdit}
            create={AudioBookCategoryCreate}
            icon={LibraryBooks}
            options={{label: "Categories Audiobooks"}}
        />
        <Resource
            name="audio-book"
            list={AudioBookList}
            edit={AudioBookEdit}
            create={AudioBookCreate}
            icon={LibraryBooks}
            options={{label: "List Audiobooks"}}
        />
        <Resource
            name="audio"
            list={AudioList}
            edit={AudioEdit}
            create={AudioCreate}
            icon={Audiotrack}
            options={{label: "File Audiobooks"}}
        />
        <Resource
            name="kidz-book-category"
            list={OptimaKidzCategoryList}
            edit={OptimaKidzCategoryEdit}
            create={OptimaKidzCategoryCreate}
            icon={LibraryBooks}
            options={{label: "Categories Kidz Books"}}
        />
        <Resource
            name="kidz-book"
            list={OptimaKidzBookList}
            edit={OptimaKidzBookEdit}
            create={OptimaKidzBookCreate}
            icon={LibraryBooks}
            options={{label: "List Kidz Books"}}
        />
        <Resource
            name="kidz-file"
            list={OptimaKidzFileList}
            edit={OptimaKidzFileEdit}
            create={OptimaKidzFileCreate}
            icon={Audiotrack}
            options={{label: "File Kidz Books"}}
        />
        {/*<Resource*/}
        {/*  name="notifications"*/}
        {/*  list={NotificationList}*/}
        {/*  edit={NotificationEdit}*/}
        {/*  create={NotificationCreate}*/}
        {/*  icon={Notifications}*/}
        {/*/>*/}
        <Resource
            name="ratio"
            list={RatioList}
            edit={RatioEdit}
            create={RatioCreate}
            icon={Album}
        />
        <Resource
            name="rolling-item"
            list={RollingItemList}
            edit={RollingItemEdit}
            create={RollingItemCreate}
            icon={Album}
        />
        <Resource
            name="rolling-result"
            list={RollingResultList}
            edit={RollingResultEdit}
            icon={Album}
        />
        <Resource
            name="event"
            list={EventList}
            icon={Album}
            options={{label: "Event Records"}}
        />
        <Resource
            name="setting"
            list={SettingList}
            edit={SettingEdit}
            create={SettingCreate}
            icon={Settings}
        />
        <Resource
            name="test"
            list={TestList}
            edit={TestEdit}
            create={TestCreate}
            icon={QuestionAnswer}
            options={{label: "Test List"}}
        />
        <Resource
            name="question"
            list={QuestionList}
            edit={QuestionEdit}
            create={QuestionCreate}
            icon={QuestionAnswer}
        />
        <Resource
            name="contract"
            list={ContractList}
            edit={ContractEdit}
            icon={Receipt}
        />
        <Resource
            name="promotion"
            list={PromotionList}
            edit={PromotionEdit}
            create={PromotionCreate}
            icon={Redeem}
        />
        <Resource
            name="special-option"
            list={SpecialOptionList}
            edit={SpecialOptionEdit}
            create={SpecialOptionCreate}
            icon={Redeem}
            options={{label: "Rank Special Option"}}
        />
        <Resource
            name="promotion-history"
            list={PromotionHistoryList}
            edit={PromotionHistoryEdit}
            icon={Redeem}
            options={{label: "Reward Promotion History"}}
        />
        <Resource
            name="transfer"
            list={TransferRequestList}
            edit={TransferRequestEdit}
            icon={AttachMoneyIcon}
            options={{label: "Transfer Request"}}
        />
        <Resource
            name="insurance"
            list={InsuranceList}
            edit={InsuranceEdit}
            icon={MonetizationOn}
            options={{label: "Insurance Register"}}
        />
        <Resource
            name="insurance-english"
            list={InsuranceEnglishList}
            edit={InsuranceEnglishEdit}
            icon={MonetizationOn}
            options={{label: "Insurance Register Course English"}}
        />
        <Resource
            name="refund"
            list={RefundHistoryList}
            edit={RefundHistoryEdit}
            icon={MonetizationOn}
            options={{label: "Refund History"}}
        />
        <Resource
            name="refund-english"
            list={RefundHistoryEnglishList}
            edit={RefundHistoryEnglishEdit}
            icon={MonetizationOn}
            options={{label: "Refund History English"}}
        />
        <Resource
            name="course-progress"
            list={CourseProgressList}
            edit={CourseProgressEdit}
            icon={FindReplace}
            options={{label: "Course Progress"}}
        />
        <Resource
            name="course-progress-english"
            list={CourseProgressEnglishList}
            edit={CourseProgressEnglishEdit}
            icon={FindReplace}
            options={{label: "Course Progress English"}}
        />
        <Resource
            name="presenter"
            list={PresenterList}
            edit={PresenterEdit}
            create={PresenterCreate}
            icon={RecordVoiceOver}
        />
        <Resource
            name="resource"
            list={ResourceList}
            edit={ResourceEdit}
            create={ResourceCreate}
            icon={VideoLibrary}
        />
        <Resource
            name="opinion"
            list={OpinionList}
            edit={OpinionEdit}
            create={OpinionCreate}
            icon={VideoLibrary}
        />
        <Resource
            name="event-week"
            list={EventWeekList}
            edit={EventWeekEdit}
            create={EventWeekCreate}
            icon={Redeem}
        />
        <Resource
            name="active-code"
            list={ActiveCodeList}
            edit={ActiveCodeEdit}
            create={ActiveCodeCreate}
            icon={Redeem}
        />
      </Admin>
    </React.Fragment>
  );
}

export default App;
