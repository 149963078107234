import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
import "./bootstrap.css";
import "./ApproveButton.scss";
import JoditEditor from "jodit-react";
import {toast} from "react-toastify";
import DefaultNoImage from "./assets/default-attachment.png";

class CourseCustomInput extends Component {
  state = {
    id: 0,
    title: "",
    title_vi: "",
    description: "",
    description_vi: "",
    level: "Beginner",
    is_english: 0,
    price: 0,
    duration: 0,
    preview: "",
    attachment: null,
  };

  componentDidMount() {
    const {record} = this.props;
    if (record.id) {
      this.setState({
        id: record.id,
        title: record.title,
        title_vi: record.title_vi,
        description: record.description,
        description_vi: record.description_vi,
        level: record.level,
        is_english: record.is_english,
        price: record.price,
        duration: record.duration,
        preview: record.avatar,
      });
    }
  }

  // getCategories = () => {
  //   let t_this = this;
  //   axios.get(baseUrl + "/ab-category-all", {
  //     headers: this.generateHeaders()
  //   }).then(function (res) {
  //     t_this.setState({categories: res.data.data})
  //   })
  // }

  handleImageChange = e => {
    let file = URL.createObjectURL(e.target.files[0]);
    this.setState({
      preview: file,
      attachment: e.target.files[0]
    });
  };

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  submit = () => {
    let data = {};
    if (this.state.attachment){
      data = new FormData();
      data.append("id", this.state.id);
      data.append("title", this.state.title);
      data.append("title_vi", this.state.title_vi);
      data.append("description", this.state.description);
      data.append("description_vi", this.state.description_vi);
      data.append("level", this.state.level);
      data.append("is_english", this.state.is_english);
      data.append("price", this.state.price);
      data.append("duration", this.state.duration);
      data.append("avatar", this.state.attachment);
    }
    else
      data = this.state;
    if (this.state.id) {
      axios.post(baseUrl + "/level-course/update/" + this.state.id, data, {
        headers: this.generateHeaders()
      }).then(response => {
        if (response.data.statusCode == 200) {
          toast.success("Update course successfully!");
          window.location.reload();
        }
        else
          toast.error(response.data.errors.join("."))
      }).catch(error => {
        toast.error("Server error!")
      })
      return;
    }
    axios.post(baseUrl + "/level-course", data, {
      headers: this.generateHeaders()
    }).then(response => {
      if (response.data.statusCode == 200) {
        toast.success("Create course successfully");
        window.location.href = window.location.href.replace("create", response.data.data.id);
      }
      else
        toast.error(response.data.errors.join(" "))
    }).catch(error => {
      toast.error("Server error!")
    })
  };

  delete = () => {
    if (!window.confirm("Do you want to delete course book?")) return;

    axios.delete(baseUrl + "/level-course/" + this.state.id, {
      headers: this.generateHeaders()
    }).then(response => {
      toast.success("Delete course successfully!");
      window.location.href = window.location.href.replace(this.state.id, "");
    }).catch(error => {
      toast.error("Server error!")
    })
  }


  // handleImageChange = e => {
  //   let file = URL.createObjectURL(e.target.files[0]);
  //   this.setState({
  //     preview: file,
  //     attachment: e.target.files[0]
  //   });
  // };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    return (
        <div className="form-group">
          <Fragment>
            <div>
              <div>Title:</div>
              <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange("title")}
                  value={this.state.title}
              />
            </div>
            <div>
              <div>Title (VN):</div>
              <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange("title_vi")}
                  value={this.state.title_vi}
              />
            </div>
            <div>
              <div>Description:</div>
              <JoditEditor
                  value={this.state.description}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={newContent => this.setState({description: newContent})} // preferred to use only this option to update the content for performance reasons
              />
            </div>
            <div>
              <div>Description (VN):</div>
              <JoditEditor
                  value={this.state.description_vi}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={newContent => this.setState({description_vi: newContent})} // preferred to use only this option to update the content for performance reasons
              />
            </div>
            <div>
              <div>Type:</div>
              <select
                  className="form-control"
                  onChange={this.handleChange("is_english")}
                  value={this.state.is_english}
              >
                <option value="0">Skill</option>
                <option value="1">English</option>
              </select>
            </div>
            <div>
              <div>Level:</div>
              <select
                  className="form-control"
                  onChange={this.handleChange("level")}
                  value={this.state.level}
              >
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advanced">Advanced</option>
                <option value="Expert">Expert</option>
                <option value="Master">Master</option>
              </select>
            </div>
            <div>
              <div>Price</div>
              <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange("price")}
                  value={this.state.price}
              />
            </div>
            <div>
              <div>Duration</div>
              <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange("duration")}
                  value={this.state.duration}
              />
            </div>
            <div className="attachment-photo">
              <img
                  className="Photo"
                  alt="attachment"
                  src={this.state.preview ? this.state.preview : DefaultNoImage}
              />
              <div>
                <div className="ChangePhotoText">Update attachment</div>
                <input
                    type="file"
                    accept="image/*"
                    name="image"
                    onChange={this.handleImageChange}
                />
              </div>

            </div>

            <div className="action-button">
              <input
                  type="button"
                  className="accept-button"
                  onClick={this.submit}
                  value="Submit"
              />
            </div>
            {(this.state.id)? (
                <div className="action-button">
                  <input
                      type="button"
                      className="accept-button"
                      onClick={this.delete}
                      value="Delete"
                  />
                </div>
            ) : <React.Fragment/>}
          </Fragment>
        </div>
    );
  }
}

export default connect(
    null,
    {}
)(CourseCustomInput);