import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  ImageField
} from "react-admin";
import OptimaKidzBookCustomInput from "./OptimaKidzBookCustomInput";

const OptimaKidzBookFilter = (props) => (
  <Filter {...props}>
    <TextInput source="title" alwaysOn/>
    <TextInput source="category_name" alwaysOn/>
  </Filter>
);

export const OptimaKidzBookList = props => (
  <List {...props} filters={<OptimaKidzBookFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="name_vi"/>
      <TextField source="order" label="Order point"/>
      <TextField source="category_name"/>
      <EditButton basePath="/kidz-book"/>
    </Datagrid>
  </List>
);

export const OptimaKidzBookEdit = props => (
  <Edit {...props}>
    <OptimaKidzBookCustomInput/>
  </Edit>
);

export const OptimaKidzBookCreate = props => (
  <Create {...props}>
    <OptimaKidzBookCustomInput/>
  </Create>
);
