import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  ImageField, SelectInput
} from "react-admin";
import ResourceCustomInput from "./ResourceCustomInput";

const ResourceFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn/>
    <TextInput source="type" alwaysOn/>
    <TextInput source="category_name" alwaysOn/>
  </Filter>
);

export const ResourceList = props => (
  <List {...props} filters={<ResourceFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="type"/>
      <TextField source="category_name"/>
      <TextField source="link" label="Download document link/Video link"/>
      <EditButton basePath="/resource"/>
    </Datagrid>
  </List>
);

export const ResourceEdit = props => (
  <Edit {...props}>
    <ResourceCustomInput/>
  </Edit>
);

export const ResourceCreate = props => (
  <Create {...props}>
    <ResourceCustomInput/>
  </Create>
);
