import React from "react";
import {
  List,
  Datagrid,
  Edit,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  downloadCSV
} from "react-admin";
import ApproveButton from "./ApproveButton";
import OrderExportButton from "./OrderExportButton";

const OrderFilter = (props) => (
  <Filter {...props}>
    <TextInput source="charge_code" alwaysOn/>
    <TextInput source="paypal_order_id" alwaysOn label="Paypal transaction"/>
  </Filter>
);

export const OrderList = props => (
  <>
    <OrderExportButton/>
    <List {...props} filters={<OrderFilter/>} filter={{is_published: true}}>
      <Datagrid>
        <TextField source="key" label="#"/>
        <TextField source="updated_at"/>
        <TextField source="charge_code"/>
        <TextField source="price"/>
        <TextField source="type"/>
        <TextField source="method" label="Payment method"/>
        <TextField source="withdraw_type"/>
        <TextField source="paypal_order_id"/>
        <TextField source="user.code"/>
        <TextField source="user.email"/>
        <TextField source="user.name"/>
        <TextField source="status"/>
        <EditButton basePath="/orders"/>
      </Datagrid>
    </List></>
);

const OrderTitle = ({record}) => {
  return <span>Order {record ? `"${record.charge_code}"` : ""}</span>;
};

export const OrderEdit = props => (
  <Edit title={<OrderTitle/>} {...props}>
    <SimpleForm>
      <DisabledInput source="key" label="ID"/>
      <DisabledInput source="updated_at"/>
      <DisabledInput source="charge_code"/>
      <DisabledInput source="price"/>
      <DisabledInput source="type"/>
      <DisabledInput source="bank_name"/>
      <DisabledInput source="bank_account"/>
      <DisabledInput source="bank_address"/>
      <DisabledInput source="swift_code"/>
      <DisabledInput source="full_name"/>
      <DisabledInput source="paypal_name"/>
      <DisabledInput source="paypal_email"/>
      <DisabledInput source="user.code"/>
      <DisabledInput source="user.email"/>
      <DisabledInput source="user.name"/>
      <DisabledInput source="status"/>
      <TextInput source="note"/>
      <ApproveButton/>
    </SimpleForm>
  </Edit>
);
