import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter
} from "react-admin";
import PartCustomInput from "./PartCustomInput";

const redirectChild = (id) => {
  window.location.href = "/#/lessons?parent=" + id;
};

const FullNameField = ({record = {}}) => <span style={{cursor: "pointer"}}
                                               onClick={redirectChild.bind(this, record.id)}>View</span>;
FullNameField.defaultProps = {label: 'Lesson Course'};

const PartFilter = (props) => (
  <Filter {...props}>
    <TextInput source="parent" label="Child Course Parent ID" alwaysOn/>
    <TextInput source="name" label="Title" alwaysOn/>
  </Filter>
);

export const PartList = props => (
  <List {...props} filters={<PartFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="title"/>
      <TextField source="title_vi" label="Vietnamese Title"/>
      <TextField source="list_course" sortable={false}/>
      <TextField source="course_type" sortable={false}/>
      <TextField source="order"/>
      <EditButton basePath="/part-course"/>
      <FullNameField source="id" sortable={false}/>
    </Datagrid>
  </List>
);

export const PartEdit = props => (
  <Edit {...props}>
    <PartCustomInput/>
  </Edit>
);

export const PartCreate = props => (
  <Create {...props}>
    <PartCustomInput/>
  </Create>
);
