import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
import "./bootstrap.css";
import "./ApproveButton.scss";
import JoditEditor from "jodit-react";
import {toast} from "react-toastify";
import DefaultNoImage from "./assets/default-attachment.png";

class CourseCustomInput extends Component {
  state = {
    id: 0,
    title: "",
    title_vi: "",
    duration: 0,
    order: 0,
    type: "Video",
    link_file: "",
    link_file_vi: "",
    link_video: "",
    link_video_vi: "",
    upload: null,
    upload_vi: null,
    part_id: 0,
    parts: []
  };

  componentDidMount() {
    const {record} = this.props;
    if (record.id) {
      this.setState({
        id: record.id,
        title: record.title,
        title_vi: record.title_vi,
        duration: record.duration,
        order: record.order,
        type: record.type,
        link_file: record.link_file,
        link_file_vi: record.link_file_vi,
        link_video: record.link_video,
        link_video_vi: record.link_video_vi,
        part_id: record.part_id
      });
    }

    this.getParts();
  }

  getParts = () => {
    let t_this = this;
    axios.get(baseUrl + "/part-course/all", {
      headers: this.generateHeaders()
    }).then(function (res) {
      t_this.setState({parts: res.data.data})
    })
  }

  // handleImageChange = e => {
  //   let file = URL.createObjectURL(e.target.files[0]);
  //   this.setState({
  //     preview: file,
  //     attachment: e.target.files[0]
  //   });
  // };

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  submit = () => {
    let data = {};
    if (this.state.upload || this.state.upload_vi) {
      data = new FormData();
      data.append("id", this.state.id);
      data.append("title", this.state.title);
      data.append("title_vi", this.state.title_vi);
      data.append("duration", this.state.duration);
      data.append("type", this.state.type);
      data.append("link_file", this.state.upload);
      data.append("link_file_vi", this.state.upload_vi);
      data.append("link_video", this.state.link_video);
      data.append("link_video_vi", this.state.link_video_vi);
      data.append("part_id", this.state.part_id ? this.state.part_id : this.state.parts[0].id);
    } else {
      data = this.state;
      if (!this.state.part_id)
        data.part_id = this.state.parts[0].id;
      data.parts = [];
    }
    if (this.state.id) {
      axios.post(baseUrl + "/lesson-course/update/" + this.state.id, data, {
        headers: this.generateHeaders()
      }).then(response => {
        if (response.data.statusCode == 200) {
          toast.success("Update lesson successfully!");
          window.location.reload();
        } else
          toast.error(response.data.errors.join("."))
      }).catch(error => {
        toast.error("Server error!")
      })
      return;
    }
    axios.post(baseUrl + "/lesson-course", data, {
      headers: this.generateHeaders()
    }).then(response => {
      if (response.data.statusCode == 200) {
        toast.success("Create lesson successfully");
        window.location.href = window.location.href.replace("create", response.data.data.id);
      } else
        toast.error(response.data.errors.join(" "))
    }).catch(error => {
      toast.error("Server error!")
    })
  };

  delete = () => {
    if (!window.confirm("Do you want to delete lesson book?")) return;

    axios.delete(baseUrl + "/lesson-course/" + this.state.id, {
      headers: this.generateHeaders()
    }).then(response => {
      toast.success("Delete lesson successfully!");
      window.location.href = window.location.href.replace(this.state.id, "");
    }).catch(error => {
      toast.error("Server error!")
    })
  }


  // handleImageChange = e => {
  //   let file = URL.createObjectURL(e.target.files[0]);
  //   this.setState({
  //     preview: file,
  //     attachment: e.target.files[0]
  //   });
  // };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    return (
        <div className="form-group">
          <Fragment>
            <div>
              <div>Title:</div>
              <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange("title")}
                  value={this.state.title}
              />
            </div>
            <div>
              <div>Title (VN):</div>
              <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange("title_vi")}
                  value={this.state.title_vi}
              />
            </div>
            <div>
              <div>Part course (ID - Title):</div>
              <select
                  className="form-control"
                  onChange={this.handleChange("part_id")}
                  value={this.state.part_id}
              >
                {this.state.parts.map((item, index) => (
                    <option key={index} value={item.id}>{[item.id, item.title].join(" - ")}</option>
                ))}
              </select>
            </div>
            <div>
              <div>Duration</div>
              <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange("duration")}
                  value={this.state.duration}
              />
            </div>
            <div>
              <div>Order</div>
              <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange("order")}
                  value={this.state.order}
              />
            </div>
            <div className="attachment-photo">
              <div>
                <div className="ChangePhotoText">Update file PDF (EN)</div>
                <p>File uploaded: <a href={this.state.link_file} target="_blank">{this.state.link_file}</a></p>
                <input
                    type="file"
                    accept="application/pdf"
                    name="image"
                    onChange={e => this.setState({upload: e.target.files[0]})}
                />
              </div>
              <div>
                <div className="ChangePhotoText">Update file PDF (VN)</div>
                <p>File uploaded: <a href={this.state.link_file_vi} target="_blank">{this.state.link_file_vi}</a></p>
                <input
                    type="file"
                    accept="application/pdf"
                    name="image"
                    onChange={e => this.setState({upload_vi: e.target.files[0]})}
                />
              </div>

            </div>
            <div>
              <div>
                <div>Link video (EN):</div>
                <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange("link_video")}
                    value={this.state.link_video}
                />
              </div>
              <div>
                <div>Link video (VN):</div>
                <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange("link_video_vi")}
                    value={this.state.link_video_vi}
                />
              </div>

            </div>

            <div className="action-button">
              <input
                  type="button"
                  className="accept-button"
                  onClick={this.submit}
                  value="Submit"
              />
            </div>
            {(this.state.id) ? (
                <div className="action-button">
                  <input
                      type="button"
                      className="accept-button"
                      onClick={this.delete}
                      value="Delete"
                  />
                </div>
            ) : <React.Fragment/>}
          </Fragment>
        </div>
    );
  }
}

export default connect(
    null,
    {}
)(CourseCustomInput);