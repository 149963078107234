import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  SelectInput
} from "react-admin";

const RefundHistoryFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" label="User name" alwaysOn/>
    <TextInput source="code" label="User code" alwaysOn/>
    <TextInput source="level" label="Course level" alwaysOn/>
  </Filter>
);

export const RefundHistoryList = props => (
  <List {...props} filters={<RefundHistoryFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="key" label="#"/>
      <TextField source="created_at" label="Date"/>
      <TextField source="user_code"/>
      <TextField source="user_name"/>
      <TextField source="user_email"/>
      <TextField source="course_level"/>
      <TextField source="amount"/>
      <TextField source="count_back"/>
      <TextField source="status"/>
      <EditButton basePath="/refund"/>
    </Datagrid>
  </List>
);

export const RefundHistoryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="key" label="ID"/>
      <DisabledInput source="created_at" label="Date"/>
      <DisabledInput source="user_code"/>
      <DisabledInput source="user_name"/>
      <DisabledInput source="user_email"/>
      <DisabledInput source="course_level"/>
      <DisabledInput source="amount"/>
      <DisabledInput source="count_back"/>
      <DisabledInput source="status"/>
    </SimpleForm>
  </Edit>
);
