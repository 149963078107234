import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  SelectInput
} from "react-admin";

const CourseProgressFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" label="User name" alwaysOn/>
    <TextInput source="code" label="User code" alwaysOn/>
    <TextInput source="email" label="User email" alwaysOn/>
  </Filter>
);

export const CourseProgressList = props => (
  <List {...props} filters={<CourseProgressFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="code" label="User Code"/>
      <TextField source="name" label="User Name"/>
      <TextField source="email" label="User Email"/>
      <TextField source="progress_beginner_en" label="Progress Beginner ENG (%)"/>
      <TextField source="progress_beginner_vi" label="Progress Beginner VIE (%)"/>
      <TextField source="progress_intermediate_en" label="Progress Intermediate ENG (%)"/>
      <TextField source="progress_intermediate_vi" label="Progress Intermediate VIE (%)"/>
      <TextField source="progress_advanced_en" label="Progress Advanced ENG (%)"/>
      <TextField source="progress_advanced_vi" label="Progress Advanced VIE (%)"/>
      <TextField source="progress_expert_en" label="Progress Expert ENG (%)"/>
      <TextField source="progress_expert_vi" label="Progress Expert VIE (%)"/>
      <TextField source="progress_master_en" label="Progress Master ENG (%)"/>
      <TextField source="progress_master_vi" label="Progress Master VIE (%)"/>
      <TextField source="progress" label="Progress (%)"/>
      <EditButton basePath="/course-progress"/>
    </Datagrid>
  </List>
);

export const CourseProgressEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <DisabledInput source="code" label="User Code"/>
      <DisabledInput source="name" label="User Name"/>
      <DisabledInput source="email" label="User Email"/>
      <DisabledInput source="progress_beginner_en" label="Progress Beginner ENG (%)"/>
      <DisabledInput source="progress_beginner_vi" label="Progress Beginner VIE (%)"/>
      <DisabledInput source="progress_intermediate_en" label="Progress Intermediate ENG (%)"/>
      <DisabledInput source="progress_intermediate_vi" label="Progress Intermediate VIE (%)"/>
      <DisabledInput source="progress_advanced_en" label="Progress Advanced ENG (%)"/>
      <DisabledInput source="progress_advanced_vi" label="Progress Advanced VIE (%)"/>
      <DisabledInput source="progress_expert_en" label="Progress Expert ENG (%)"/>
      <DisabledInput source="progress_expert_vi" label="Progress Expert VIE (%)"/>
      <DisabledInput source="progress_master_en" label="Progress Master ENG (%)"/>
      <DisabledInput source="progress_master_vi" label="Progress Master VIE (%)"/>
      <DisabledInput source="progress" label="Progress (%)"/>
    </SimpleForm>
  </Edit>
);
