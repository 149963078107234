import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  ImageField,
  SelectInput,
  SelectField,
  NumberInput
} from "react-admin";
import PromotionCustomInput from "./PromotionCustomInput";

const PromotionFilter = (props) => (
  <Filter {...props}>
    <TextInput source="rank" alwaysOn/>
    <TextInput source="title" alwaysOn/>
  </Filter>
);

export const PromotionList = props => (
  <List {...props} filters={<PromotionFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="rank"/>
      <TextField source="title"/>
      <TextField source="detail"/>
      <TextField source="point"/>
      <EditButton basePath="/promotion"/>
    </Datagrid>
  </List>
);

export const PromotionEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <SelectInput
        source="rank"
        choices={[
          { id: "member", name: "Member" },
          { id: "gold", name: "Gold" },
          { id: "platinum", name: "Platinum" }
        ]}
      />
      <TextInput source="title"/>
      <TextInput source="detail"/>
      <NumberInput source="point"/>
      <ImageField source="image" />
    </SimpleForm>
  </Edit>
);

export const PromotionCreate = props => (
  <Create {...props}>
    <PromotionCustomInput/>
  </Create>
);
