import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  FunctionField
} from "react-admin";

const EventFilter = (props) => (
  <Filter {...props}>
    <TextInput source="title" label="Code" alwaysOn />
    <TextInput source="name" alwaysOn />
  </Filter>
);

export const EventList = props => (
  <List {...props} filters={<EventFilter/>}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="code"/>
      <TextField source="roll_amount"/>
      <TextField source="rolled"/>
      <TextField source="adding" label="Money No Add To Wallet"/>
      <TextField source="added" label="Money Added To Wallet"/>
    </Datagrid>
  </List>
);
