import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  SelectInput
} from "react-admin";
import CustomTransferRequestButton from "./CustomTransferRequestButton";

const TransferRequestFilter = (props) => (
  <Filter {...props}>
    <TextInput source="transfer_code" label="Transfer code" alwaysOn/>
    <TextInput source="code_send" label="Code (User Send)" alwaysOn/>
    <TextInput source="code_receive" label="Code (User Receive)" alwaysOn/>
  </Filter>
);

export const TransferRequestList = props => (
  <List {...props} filters={<TransferRequestFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="code"/>
      <TextField source="user_code"/>
      <TextField source="user_name"/>
      <TextField source="user_email"/>
      <TextField source="target_code"/>
      <TextField source="target_name"/>
      <TextField source="target_email"/>
      <TextField source="amount"/>
      <TextField source="status"/>
      <EditButton basePath="/transfer"/>
    </Datagrid>
  </List>
);

export const TransferRequestEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <DisabledInput source="code"/>
      <DisabledInput source="user_code"/>
      <DisabledInput source="user_name"/>
      <DisabledInput source="user_email"/>
      <DisabledInput source="target_code"/>
      <DisabledInput source="target_name"/>
      <DisabledInput source="target_email"/>
      <DisabledInput source="amount"/>
      <CustomTransferRequestButton />
    </SimpleForm>
  </Edit>
);
