import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  LongTextInput,
  NumberInput,
  SelectInput,
  Filter
} from "react-admin";

const CourseEnglishFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" label="Title" alwaysOn/>
  </Filter>
);

const redirectChild = (id) => {
  window.location.href = "/#/child-course?parent=" + id;
};

const FullNameField = ({record = {}}) => <span style={{cursor: "pointer"}}
                                               onClick={redirectChild.bind(this, record.id)}>View</span>;
FullNameField.defaultProps = {label: 'Child Course English'};

export const CourseEnglishList = props => (
  <List {...props} filters={<CourseEnglishFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="title"/>
      <TextField source="title_vi" label="Vietnamese Title"/>
      <TextField source="description"/>
      <TextField source="description_vi" label="Description Vietnamese"/>
      <TextField source="level"/>
      <TextField source="price"/>
      <EditButton basePath="/courses-english"/>
      <FullNameField source="id"/>
    </Datagrid>
  </List>
);

const CourseEnglishTitle = ({record}) => {
  return <span>Course {record ? `"${record.title}"` : ""}</span>;
};

const levelChoices = [
  {id: "Beginner", name: "Beginner"},
  {id: "Intermediate", name: "Intermediate"},
  {id: "Advanced", name: "Advanced"},
  {id: "Expert", name: "Expert"},
  {id: "Master", name: "Master"}
];

export const CourseEnglishEdit = props => (
  <Edit title={<CourseEnglishTitle/>} {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <TextInput source="title"/>
      <TextInput source="title_vi" label="Vietnamese Title"/>
      <LongTextInput source="description"/>
      <LongTextInput source="description_vi" label="Description Vietnamese"/>
      <SelectInput source="level" choices={levelChoices}/>
      <NumberInput source="price" step={100}/>
      <TextInput source="duration"/>
    </SimpleForm>
  </Edit>
);

export const CourseEnglishCreate = props => (
  <Create title="Create a Course" {...props}>
    <SimpleForm>
      <TextInput disabled source="is_english" defaultValue="1"/>
      <TextInput source="title"/>
      <TextInput source="title_vi" label="Vietnamese Title"/>
      <LongTextInput source="description"/>
      <LongTextInput source="description_vi" label="Description Vietnamese"/>
      <SelectInput source="level" choices={levelChoices}/>
      <NumberInput source="price" step={100}/>
      <TextInput source="duration"/>
    </SimpleForm>
  </Create>
);
