import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
// import "./ApproveButton.scss";
import "./bootstrap.css";
import DefaultNoImage from "./assets/default-attachment.png";

class PresenterCustomInput extends Component {
  state = {
    name: "",
    level: "",
    description: "",
    preview: "",
    attachment: {},
    educateProcess: "",
    teachingArray: []
  };

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  submit = async () => {
    let form_data = new FormData();
    form_data.append("name", this.state.name);
    form_data.append("level", this.state.level);
    form_data.append("description", this.state.description);
    form_data.append("educate_process", this.state.educateProcess);
    form_data.append("teaching_process", JSON.stringify(this.state.teachingArray));
    if (this.state.attachment)
      form_data.append("avatar", this.state.attachment);
    axios
      .post(baseUrl + "/presenter", form_data, {
        headers: this.generateHeaders()
      })
      .then(response => {
        alert("Create presenter successfully");
        window.location.href = window.location.href.replace("create", response.data.data.id);
      }).catch(error => {
      alert(error.response.data.message)
    })
  };

  handleImageChange = e => {
    let file = URL.createObjectURL(e.target.files[0]);
    this.setState({
      preview: file,
      attachment: e.target.files[0]
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChangeTeachingRow = (e, index, name) => {
    let val = e.target.value;
    this.setState(state => {
      let arr = state.teachingArray;
      arr[index][name] = val;
      state.teachingArray = arr;
      return state;
    })
  }

  createTeachingRow = () => {
    let row = {
      start: "",
      end: "",
      location: "",
      description: ""
    };
    this.setState(state => {
      let arr = state.teachingArray;
      arr.push(row);
      state.teachingArray = arr;
      return state;
    })
  }

  render() {
    return (
      <div className="form-group">
        <Fragment>
          <div>
            <div>Name:</div>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("name")}
            />
          </div>
          <div>
            <div>Level - Position:</div>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("level")}
            />
          </div>
          <div>
            <div>Description:</div>
            <textarea
              className="form-control"
              onChange={this.handleChange("description")}
              rows={5}
            />
          </div>
          <div>
            <div>Educate Progress (About me)</div>
            <textarea
              className="form-control"
              onChange={this.handleChange("educateProcess")}
              rows={5}
            />
          </div>
          <div>
            <div>Teaching Progress</div>
            <table className="table table-bordered">
              <thead>
              <tr>
                <td>Start year</td>
                <td>End year</td>
                <td>Location</td>
                <td>Description</td>
                <td>
                  <button type="button" onClick={() => this.createTeachingRow()}>Add row</button>
                </td>
              </tr>
              </thead>
              <tbody>
              {this.state.teachingArray.map((item, index) => (
                <tr key={index}>
                  <td style={{padding: "10px"}}><input type="number" onChange={event => this.handleChangeTeachingRow(event, index, "start")} className="form-control"/></td>
                  <td style={{padding: "10px"}}><input type="number" onChange={event => this.handleChangeTeachingRow(event, index, "end")} className="form-control"/></td>
                  <td style={{padding: "10px"}}><input type="text" onChange={event => this.handleChangeTeachingRow(event, index, "location")} className="form-control"/></td>
                  <td style={{padding: "10px"}}><textarea rows={5} onChange={event => this.handleChangeTeachingRow(event, index, "description")} className="form-control"/></td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div className="attachment-photo">
            <img
              className="Photo"
              alt="attachment"
              src={this.state.preview ? this.state.preview : DefaultNoImage}
            />
            <div>
              <div className="ChangePhotoText">Update attachment (recommended size: 352 x 273px)</div>
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={this.handleImageChange}
              />
            </div>
          </div>
          <div className="action-button">
            <input
              type="button"
              className="accept-button"
              onClick={this.submit}
              value="Submit"
            />
          </div>
        </Fragment>
      </div>
    );
  }
}

export default connect(
  null,
  {}
)(PresenterCustomInput);