import {stringify} from "query-string";
import axios from "axios";
import {GET_LIST, GET_ONE, CREATE, UPDATE, DELETE} from "react-admin";
import {baseUrl, tokenKey, usernameKey} from "./authProvider";

const apiUrl = baseUrl;

function convertFileToBase64(file){
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  })};

export default async (type, resource, params) => {
  let url = "";
  let method = "get";
  let body = {};
  const headers = {
    Accept: "application/json"
  };
  const token = localStorage.getItem(tokenKey);
  if (token !== "") {
    headers["Authorization"] = `Bearer ${token}`;
  }
  if (resource === "attachments" && type === "CREATE") {
    // console.log(params);
    // params.data.rawFile
    if (params.data.file) {
      console.log(params.data.file);
    }
  }

  switch (type) {
    case GET_LIST: {
      const {page, perPage} = params.pagination;
      const {field, order} = params.sort;
      var query = {
        _sort: field,
        _order: order,
        _page: page,
        _length: perPage,
        _name: params.filter.name,
        _member_name: params.filter.member_name,
        _email: params.filter.email,
        _code: params.filter.charge_code,
        _parent: params.filter.parent,
        _test: params.filter.test,
        _paypal_code: params.filter.paypal_order_id,
        _status: params.filter.status,
        _user_code: params.filter.code,
        _id_card: params.filter.id_card,
        _rank: params.filter.rank,
        _title: params.filter.title,
        _transfer_code: params.filter.transfer_code,
        _level: params.filter.level,
        _type: params.filter.type,
        _category: params.filter.category_name,
        _part_id: params.filter.part_id,
        _child_course_id: params.filter.child_course_id,
        _course_id: params.filter.course_id,
        _code_send: params.filter.code_send,
        _code_receive: params.filter.code_receive,
        _book_name: params.filter.book_name,
      };
      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }
    case GET_ONE:
      url = `${apiUrl}/${resource}/${params.id}`;
      break;
    case CREATE:
      url = `${apiUrl}/${resource}`;
      method = "post";
      body = params.data;
      break;
    case UPDATE:
      url = `${apiUrl}/${resource}/${params.id}`;
      method = "put";
      body = params.data;
      break;
    case DELETE:
      url = `${apiUrl}/${resource}/${params.id}`;
      method = "delete";
      break;
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  return axios
    .request({
      url,
      method,
      headers,
      data: body
    })
    .then(response => {
      switch (type) {
        case GET_LIST:
          return {
            data: response.data.data,
            total: parseInt(
              response.headers["x-total-count"].split("/").pop(),
              10
            )
          };
        case GET_ONE:
          return {
            data: response.data.data
          };
        case CREATE:
        case UPDATE:
        case DELETE:
          return {
            data: response.data.data
          };
        default:
          return;
      }
    })
    .catch(err => {
      if (
        err.response.status === 401 ||
        (err.response.data && err.response.data.message === "Unauthenticated.")
      ) {
        localStorage.removeItem(usernameKey);
        localStorage.removeItem(tokenKey);
        window.location.hash = "#/login";
      }

      alert(JSON.stringify(err.response));
    });
};
