import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "react-admin";
import axios from "axios";

export const baseUrl = process.env.REACT_APP_API_ENV + "/v1";
export const tokenKey = "token";
export const usernameKey = "username";

export const authProvider = (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    localStorage.setItem(usernameKey, username);
    return axios
      .post(
        baseUrl + "/login",
        {
          email: username,
          password: password
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.data && response.data.data) {
          localStorage.setItem(tokenKey, response.data.data.token);
        }
      });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem(usernameKey);
    localStorage.removeItem(tokenKey);
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem(usernameKey);
      localStorage.removeItem(tokenKey);
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem(usernameKey)
      ? Promise.resolve()
      : Promise.reject();
  }
  return Promise.reject("Unknown method");
};
