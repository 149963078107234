import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  SelectInput
} from "react-admin";
import ChildCourseCustomInput from "./ChildCourseCustomInput";

const redirectChild = (id) => {
  window.location.href = "/#/part-course?parent=" + id;
};

const FullNameField = ({record = {}}) => <span style={{cursor: "pointer"}}
                                               onClick={redirectChild.bind(this, record.id)}>View</span>;
FullNameField.defaultProps = {label: 'Part Course'};

const ChildCourseFilter = (props) => (
  <Filter {...props}>
    <TextInput source="parent" label="Course Parent ID" alwaysOn/>
    <TextInput source="name" label="Title" alwaysOn/>
  </Filter>
);

export const ChildCourseList = props => (
  <List {...props} filters={<ChildCourseFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="title"/>
      <TextField source="title_vi" label="Vietnamese Title"/>
      <TextField source="level_course" label="Level course" sortable={false}/>
      <TextField source="type" label={"Course Type"}/>
      <TextField source="order"/>
      <EditButton basePath="/list-course"/>
      <FullNameField source="id" sortable={false}/>
    </Datagrid>
  </List>
);

const ChildCourseTitle = ({record}) => {
  return <span>Child Course {record ? `"${record.title}"` : ""}</span>;
};

export const ChildCourseEdit = props => (
  <Edit title={<ChildCourseTitle/>} {...props}>
    <ChildCourseCustomInput/>
  </Edit>
);

export const ChildCourseCreate = props => (
  <Create {...props}>
    <ChildCourseCustomInput/>
  </Create>
);
