import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  ImageField
} from "react-admin";
import QuestionCustomInput from "./QuestionCustomInput";

const QuestionFilter = (props) => (
  <Filter {...props}>
    <TextInput source="test" alwaysOn/>
  </Filter>
);

export const QuestionList = props => (
  <List {...props} filters={<QuestionFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="question"/>
      <TextField source="answer_right" label="Answer"/>
      <TextField source="test_id"/>
      <EditButton basePath="/question"/>
    </Datagrid>
  </List>
);

export const QuestionEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <TextInput source="test_id" label="Test ID"/>
      <TextInput source="question"/>
      <TextInput source="answer_A" label="A"/>
      <TextInput source="answer_B" label="B"/>
      <TextInput source="answer_C" label="C"/>
      <TextInput source="answer_D" label="D"/>
      <TextInput source="answer_right" label="Answer"/>
      <ImageField source="image"/>
    </SimpleForm>
  </Edit>
);

export const QuestionCreate = props => (
  <Create {...props}>
    <QuestionCustomInput/>
  </Create>
);
