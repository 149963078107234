import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  SelectInput, LongTextInput, NumberInput
} from "react-admin";

const OptimaKidzCategoryFilter = (props) => (
    <Filter {...props}>
      <TextInput source="name" label="name" alwaysOn/>
    </Filter>
);

export const OptimaKidzCategoryList = props => (
    <List {...props} filters={<OptimaKidzCategoryFilter/>} filter={{is_published: true}}>
      <Datagrid>
        <TextField source="id"/>
        <TextField source="name"/>
        <TextField source="name_vi"/>
        <TextField source="type"/>
        <TextField source="order"/>
        <EditButton basePath="/kidz-book-category"/>
      </Datagrid>
    </List>
);

export const OptimaKidzCategoryEdit = props => (
    <Edit {...props}>
      <SimpleForm>
        <DisabledInput source="id"/>
        <TextInput source="name"/>
        <TextInput source="name_vi"/>
        <SelectInput source="type" choices={[
          {id: "audio", name: "Audio"},
          {id: "video", name: "Video"},
        ]}/>
        <NumberInput source="order"/>
      </SimpleForm>
    </Edit>
);

export const OptimaKidzCategoryCreate = props => (
    <Create title="Create a category" {...props}>
      <SimpleForm>
        <TextInput source="name"/>
        <TextInput source="name_vi"/>
        <SelectInput source="type" choices={[
          {id: "audio", name: "Audio"},
          {id: "video", name: "Video"},
        ]}/>
        <NumberInput source="order"/>
      </SimpleForm>
    </Create>
);
