import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
import "./bootstrap.css";
import "./ApproveButton.scss";
import JoditEditor from "jodit-react";
import {toast} from "react-toastify";
import DefaultNoImage from "./assets/default-attachment.png";

class ChildCourseCustomInput extends Component {
  state = {
    id: 0,
    title: "",
    title_vi: "",
    order: 0,
    parent: 0,
    courses: []
  };

  componentDidMount() {
    const {record} = this.props;
    if (record.id) {
      this.setState({
        id: record.id,
        title: record.title,
        title_vi: record.title_vi,
        order: record.order,
        parent: record.parent
      });
    }

    this.getCourses();
  }

  getCourses = () => {
    let t_this = this;
    axios.get(baseUrl + "/level-course/all", {
      headers: this.generateHeaders()
    }).then(function (res) {
      t_this.setState({courses: res.data.data})
    })
  }

  handleImageChange = e => {
    let file = URL.createObjectURL(e.target.files[0]);
    this.setState({
      preview: file,
      attachment: e.target.files[0]
    });
  };

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  submit = () => {
    
    let data = this.state;
    data.courses = [];
    if (data.id) {
      axios.put(baseUrl + "/list-course/" + this.state.id, data, {
        headers: this.generateHeaders()
      }).then(response => {
        if (response.data.statusCode == 200) {
          toast.success("Update course successfully!");
          window.location.reload();
        }
        else
          toast.error(response.data.errors.join("."))
      }).catch(error => {
        toast.error("Server error!")
      })
      return;
    }
    axios.post(baseUrl + "/list-course", data, {
      headers: this.generateHeaders()
    }).then(response => {
      if (response.data.statusCode == 200) {
        toast.success("Create course successfully");
        window.location.href = window.location.href.replace("create", response.data.data.id);
      }
      else
        toast.error(response.data.errors.join(" "))
    }).catch(error => {
      toast.error("Server error!")
    })
  };

  delete = () => {
    if (!window.confirm("Do you want to delete course book?")) return;

    axios.delete(baseUrl + "/list-course/" + this.state.id, {
      headers: this.generateHeaders()
    }).then(response => {
      toast.success("Delete course successfully!");
      window.location.href = window.location.href.replace(this.state.id, "");
    }).catch(error => {
      toast.error("Server error!")
    })
  }


  // handleImageChange = e => {
  //   let file = URL.createObjectURL(e.target.files[0]);
  //   this.setState({
  //     preview: file,
  //     attachment: e.target.files[0]
  //   });
  // };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    return (
        <div className="form-group">
          <Fragment>
            <div>
              <div>Title:</div>
              <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange("title")}
                  value={this.state.title}
              />
            </div>
            <div>
              <div>Title (VN):</div>
              <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange("title_vi")}
                  value={this.state.title_vi}
              />
            </div>
            <div>
              <div>Order</div>
              <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange("order")}
                  value={this.state.order}
              />
            </div>
            <div>
              <div>Level course (Title - Level - Type):</div>
              <select
                  className="form-control"
                  onChange={this.handleChange("parent")}
                  value={this.state.parent}
              >
                {this.state.courses.map((item, index) => (
                    <option key={index} value={item.id}>{[item.title, item.level, item.type].join(" - ")}</option>
                ))}
              </select>
            </div>
            <div className="action-button">
              <input
                  type="button"
                  className="accept-button"
                  onClick={this.submit}
                  value="Submit"
              />
            </div>
            {(this.state.id)? (
                <div className="action-button">
                  <input
                      type="button"
                      className="accept-button"
                      onClick={this.delete}
                      value="Delete"
                  />
                </div>
            ) : <React.Fragment/>}
          </Fragment>
        </div>
    );
  }
}

export default connect(
    null,
    {}
)(ChildCourseCustomInput);