import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { baseUrl, tokenKey } from "./authProvider";
import "./ApproveButton.scss";
import DefaultNoImage from "./assets/default-attachment.png";

class StatusButtonRollingResult extends Component {
  state = {
    preview: "",
    attachment: {},
    reason: ""
  };

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  buttonClick = status => {
    const { record } = this.props;
    let cf = window.confirm("Are you sure to change status?");
    if (cf === true) {
      axios
        .put(baseUrl + "/rolling-result/" + record.id, {status: status}, {
          headers: this.generateHeaders()
        })
        .then(response => {
          window.confirm("Change to " + status + " successfully!") && window.location.reload();
        })
        .catch(error => {
          window.alert("Change to " + status + " failed, error:" + JSON.stringify(error.response));
        });
    }
  };

  render() {
    return (
      <div>
          <Fragment>
            <div className="action-button">
              {this.props.record.status !== "Delivering" && (
                <input
                type="button"
                className="accept-button"
                onClick={() => this.buttonClick("delivering")}
                value="Delivering"
              />
              )}
              {this.props.record.status !== "Delivered" && (
                <input
                type="button"
                className="deny-button"
                onClick={() => this.buttonClick("delivered")}
                value="Delivered"
              />
              )}
            </div>
          </Fragment>
      </div>
    );
  }
}

export default connect(
  null,
  {}
)(StatusButtonRollingResult);
