import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  FunctionField
} from "react-admin";

const RatioFilter = (props) => (
  <Filter {...props}>
    <TextInput source="reward" label="Reward Prize" alwaysOn/>
  </Filter>
);

export const RatioList = props => (
  <List {...props} filters={<RatioFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <FunctionField label="Reward" render={row => row.reward + "$"}/>
      <FunctionField label="Percent" render={row => row.percent + "%"}/>
      <EditButton basePath="/ratio"/>
      {/*<FullNameField source="id"/>*/}
    </Datagrid>
  </List>
);

export const RatioEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <TextInput source="reward"/>
      <TextInput source="percent"/>
    </SimpleForm>
  </Edit>
);

export const RatioCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="reward"/>
      <TextInput source="percent"/>
    </SimpleForm>
  </Create>
);
