import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
import "./ApproveButton.scss";
import DefaultNoImage from "./assets/default-attachment.png";

class QuestionCustomInput extends Component {
  state = {
    test_id: 0,
    question: "",
    answer_A: "",
    answer_B: "",
    answer_C: "",
    answer_D: "",
    answer_right: "",
    preview: "",
    attachment: {}
  };

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  submit = () => {
    let form_data = new FormData();
    form_data.append("test_id", this.state.test_id);
    form_data.append("question", this.state.question);
    form_data.append("answer_A", this.state.answer_A);
    form_data.append("answer_B", this.state.answer_B);
    form_data.append("answer_C", this.state.answer_C);
    form_data.append("answer_D", this.state.answer_D);
    form_data.append("answer_right", this.state.answer_right);
    if (this.state.attachment)
      form_data.append("image", this.state.attachment);
    axios
    .post(baseUrl + "/question", form_data, {
      headers: this.generateHeaders()
    })
    .then(response => {
      alert("Create question successfully");
      let link = window.location.href.replace("create", response.data.data.id);
      window.location.href = link;
    }).catch(error => {
      alert(error.response.data.message)
    })
  };

  handleImageChange = e => {
    let file = URL.createObjectURL(e.target.files[0]);
    this.setState({
      preview: file,
      attachment: e.target.files[0]
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    return (
      <div className="form-group">
        <Fragment>
          <div>
            <div>Test ID:</div>
            <input
              type="number"
              className="form-control"
              onChange={this.handleChange("test_id")}
            />
          </div>
          <div>
            <div>Question:</div>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("question")}
            />
          </div>
          <div>
            <div>Answer A:</div>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("answer_A")}
            />
          </div>
          <div>
            <div>Answer B:</div>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("answer_B")}
            />
          </div>
          <div>
            <div>Answer C:</div>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("answer_C")}
            />
          </div>
          <div>
            <div>Answer D:</div>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("answer_D")}
            />
          </div>
          <div>
            <div>Answer Right:</div>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("answer_right")}
            />
          </div>
          <div className="attachment-photo">
            <img
              className="Photo"
              alt="attachment"
              src={this.state.preview ? this.state.preview : DefaultNoImage}
            />
            <div>
              <div className="ChangePhotoText">Update attachment</div>
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={this.handleImageChange}
              />
            </div>
          </div>
          <div className="action-button">
            <input
              type="button"
              className="accept-button"
              onClick={this.submit}
              value="Submit"
            />
          </div>
        </Fragment>
      </div>
    );
  }
}

export default connect(
  null,
  {}
)(QuestionCustomInput);