import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  SelectInput
} from "react-admin";

const CommissionHistoryAudioLicenseFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" label="User name" alwaysOn/>
    <TextInput source="email" label="User email" alwaysOn/>
    <TextInput source="code" label="User code" alwaysOn/>
  </Filter>
);

export const CommissionHistoryAudioLicenseList = props => (
  <List {...props} sort={{field: "id", order: "DESC"}} filters={<CommissionHistoryAudioLicenseFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id" label="#"/>
      <TextField source="created_at" label="Date"/>
      <TextField source="user_name"/>
      <TextField source="user_code"/>
      <TextField source="user_email"/>
      <TextField source="buy_type_name" label="From"/>
      <TextField source="license_type" label="License time"/>
      <TextField source="parent_name" label="Parent name"/>
      <TextField source="parent_code" label="Parent code"/>
      <TextField source="percent_f1"/>
      <TextField source="f1_commission" label="F1 receive"/>
      <TextField source="profit_company"/>
      <EditButton basePath="/commission-history-audio-license"/>
    </Datagrid>
  </List>
);

export const CommissionHistoryAudioLicenseEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id" label="#"/>
      <DisabledInput source="created_at" label="Date"/>
      <DisabledInput source="user_name"/>
      <DisabledInput source="user_code"/>
      <DisabledInput source="user_email"/>
      <DisabledInput source="buy_type_name" label="Buying source"/>
      <DisabledInput source="license_type" label="License time"/>
      <DisabledInput source="parent_name" label="Parent name"/>
      <DisabledInput source="parent_code" label="Parent code"/>
      <DisabledInput source="percent_f1"/>
      <DisabledInput source="f1_commission" label="F1 receive"/>
      <DisabledInput source="profit_company"/>
    </SimpleForm>
  </Edit>
);
