import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  FunctionField, ImageField
} from "react-admin";
import ApproveButton from "./ApproveButton";
import StatusButtonRollingResult from "./StatusButtonRollingResult";

const RollingResultFilter = (props) => (
  <Filter {...props}>
    <TextInput source="code" label="Code" alwaysOn />
    <TextInput source="name" label="User name" alwaysOn />
    <TextInput source="email" label="User email" alwaysOn />
  </Filter>
);

export const RollingResultList = props => (
  <List {...props} filters={<RollingResultFilter/>}>
    <Datagrid>
      <TextField source="key" label="#"/>
      <TextField source="created_at" label="Rolling Date"/>
      <TextField source="code"/>
      <TextField source="user_name"/>
      <TextField source="user_code"/>
      <TextField source="user_email"/>
      <TextField source="item_name" label="Gift name"/>
      <TextField source="item_type" label="Gift type"/>
      <TextField source="item_price" label="Gift price"/>
      <TextField source="receiver_name"/>
      <TextField source="receiver_phone"/>
      <TextField source="receiver_address"/>
      <TextField source="status"/>
      <EditButton basePath="/rolling-result"/>
    </Datagrid>
  </List>
);

export const RollingResultEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="key" label="ID"/>
      <DisabledInput source="code"/>
      <DisabledInput source="user_name"/>
      <DisabledInput source="user_code"/>
      <DisabledInput source="user_email"/>
      <DisabledInput source="item_name" label="Gift name"/>
      <DisabledInput source="item_type" label="Gift type"/>
      <DisabledInput source="item_price" label="Gift price"/>
      <TextInput source="receiver_name"/>
      <TextInput source="receiver_phone"/>
      <TextInput source="receiver_address"/>
      <DisabledInput source="status"/>
      <StatusButtonRollingResult/>
    </SimpleForm>
  </Edit>
);
