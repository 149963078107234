import React, {Component, Fragment} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import axios from "axios";
import { baseUrl, tokenKey } from "./authProvider";

class Dashboard extends Component {
  state = {
    data: {
      "day": {
        "deposit": 0,
        "admin-deposit": 0,
        "withdraw": 0,
        "total-revenue": 0,
        "total-commission": 0,
        "courses": []
      },
      "month": {
        "deposit": 0,
        "admin-deposit": 0,
        "withdraw": 0,
        "total-revenue": 0,
        "total-commission": 0,
        "courses": []
      },
      "year": {
        "deposit": 0,
        "admin-deposit": 0,
        "withdraw": 0,
        "total-revenue": 0,
        "total-commission": 0,
        "courses": []
      },
      "total": {
        "deposit": 0,
        "admin-deposit": 0,
        "withdraw": 0,
        "total-revenue": 0,
        "total-commission": 0,
        "courses": []
      },
      "total_user": 0,
      "total_user_active": 0,
      "total_wallet": 0,
      "have_sale": 0,
      "roll_amount": 0,
      "rolled": 0,
      "total_result": 0,
      "total_transfer_to_wallet": 0,
      "total_not_transfer_to_wallet": 0
    }
  };
  componentDidMount() {
    const token = localStorage.getItem(tokenKey);
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };

    axios
      .get(baseUrl + "/admin/dashboard", {
        headers
      })
      .then(response => {
        this.setState({data: response.data.data});
      })
      .catch(error => {
        alert(JSON.stringify(error.response));
      });
  }

  render() {
    const { data } = this.state;
    let date = new Date();
    let arr = [
      {key: "total", "value": ""},
      {key: "day", "value": date.getDate()},
      {key: "month", "value": parseInt(date.getMonth()) + 1},
      {key: "year", "value": date.getFullYear()},
    ];
    return (
      <Fragment>
        {arr.map((item, index) => (
          <Card key={index}>
            <CardHeader title={"For " + item.key.toUpperCase() + " " + item.value} />
            <CardContent>Total deposit: ${parseInt(data[item.key].deposit)}</CardContent>
            <CardContent>Total admin deposit: ${parseInt(data[item.key]["admin-deposit"])}</CardContent>
            <CardContent>Total withdraw: ${parseInt(data[item.key].withdraw)}</CardContent>
            <CardContent>Total revenue: ${parseInt(data[item.key]["total-revenue"])}</CardContent>
            <CardContent>Total commission: ${parseInt(data[item.key]["total-commission"])}</CardContent>
            <CardContent>
              {data[item.key].courses.map(course => (
                <ul key={course.id}>
                  <li>Title: {String(course.level).toUpperCase() + " - " + course.title}</li>
                  <li>Revenue: ${course.revenue}</li>
                  <li>Active count: {course.count} times</li>
                </ul>
              ))}
            </CardContent>
            {item.key === "total" && (
              <Fragment>
                <CardContent>Total users: {data.total_user} users</CardContent>
                <CardContent>Total active user: {data.total_user_active} users</CardContent>
                <CardContent>Total wallet: {data.total_wallet}$</CardContent>
                <CardHeader title="Events" />
                <CardContent>Total user have sales: {data.have_sale} users</CardContent>
                <CardContent>Total roll amount: {data.roll_amount} roll</CardContent>
                <CardContent>Total roll have used: {data.rolled} roll</CardContent>
                <CardContent>Total result of customer: ${parseInt(data.total_result)}</CardContent>
                <CardContent>Total result transfer to wallet: ${parseInt(data.total_transfer_to_wallet)}</CardContent>
                <CardContent>Total result not transfer to wallet: ${parseInt(data.total_not_transfer_to_wallet)}</CardContent>
              </Fragment>
            )}
          </Card>
        ))}
      </Fragment>
    )
  }
}

export default Dashboard;
