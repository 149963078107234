import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
import "./ApproveButton.scss";
import DefaultNoImage from "./assets/default-attachment.png";

class OrderExportButton extends Component {

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  submit = () => {
    axios
    .post(baseUrl + "/export-order", {}, {
      headers: this.generateHeaders()
    })
    .then(response => {
      alert("Export order successfully");
      let link = response.data.url;
      window.open(
        link,
        "_blank"
      )
    }).catch(error => {
      alert(error.response.data.message)
    })
  };

  render() {
    return (
      <Fragment>
        <div className="form-group">
          <button className="btn btn-primary" onClick={() => this.submit()}>Export Order</button>
        </div>
      </Fragment>
    );
  }
}

export default connect(
  null,
  {}
)(OrderExportButton);
