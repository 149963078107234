import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
import "./ApproveButton.scss";
import DefaultNoImage from "./assets/default-attachment.png";

class AttachmentCustomInput extends Component {
  state = {
    lesson_id: 0,
    link_file: "",
    type: "Video",
    attachment: ""
  };

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  submit = () => {
    let form_data = new FormData();
    form_data.append("lesson_id", this.state.lesson_id);
    form_data.append("type", this.state.type);
    form_data.append("link_file", this.state.link_file);
    form_data.append("file", this.state.attachment);
    axios
    .post(baseUrl + "/attachments", form_data, {
      headers: this.generateHeaders()
    })
    .then(response => {
      alert("Create attachment successfully");
      let link = window.location.href.replace("create", response.data.data.id);
      window.location.href = link;
    }).catch(error => {
      alert(error.response.data.message)
    })
  };

  handleImageChange = e => {
    this.setState({
      attachment: e.target.files[0]
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    return (
      <div className="form-group">
        <Fragment>
          <div>
            <div>Lesson ID:</div>
            <input
              type="number"
              className="form-control"
              onChange={this.handleChange("lesson_id")}
            />
          </div>
          <div>
            <div>Type:</div>
            <select className="form-control" onChange={this.handleChange("type")}>
              <option value="Video">Video</option>
              <option value="PDF">PDF</option>
            </select>
          </div>
          {this.state.type === "Video" && (
            <div>
            <div>Your video url:</div>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("link_file")}
            />
          </div>
          )}
          {this.state.type === "PDF" && (
          <div className="attachment-photo">
            <div>
              <div className="ChangePhotoText">Upload attachment</div>
              <input
                type="file"
                accept="application/pdf"
                onChange={this.handleImageChange}
              />
            </div>
          </div>
          )}
          <div className="action-button">
            <input
              type="button"
              className="accept-button"
              onClick={this.submit}
              value="Submit"
            />
          </div>
        </Fragment>
      </div>
    );
  }
}

export default connect(
  null,
  {}
)(AttachmentCustomInput);
