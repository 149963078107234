import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  ImageField
} from "react-admin";
import RollingItemCustomInput from "./RollingItemCustomInput";

const RollingItemFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn/>
    <TextInput source="type" alwaysOn/>
  </Filter>
);

export const RollingItemList = props => (
  <List {...props} filters={<RollingItemFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="name_vi" label="Name Vietnamese"/>
      <TextField source="type"/>
      <TextField source="price" label="Price ($)"/>
      <EditButton basePath="/rolling-item"/>
    </Datagrid>
  </List>
);

export const RollingItemEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <TextInput source="name"/>
      <TextInput source="name_vi" label="Name Vietnamese"/>
      <TextInput source="type"/>
      <TextInput source="price" label="Price ($)"/>
      <ImageField source="image"/>
    </SimpleForm>
  </Edit>
);

export const RollingItemCreate = props => (
  <Create {...props}>
    <RollingItemCustomInput/>
  </Create>
);
