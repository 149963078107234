import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
import "./ApproveButton.scss";
import DefaultNoImage from "./assets/default-attachment.png";

class RollingItemCustomInput extends Component {
  state = {
    name: "",
    name_vi: "",
    type: "money",
    price: 0,
    showInputImage: false,
    preview: "",
    attachment: null
  };

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  submit = () => {
    let form_data = new FormData();
    form_data.append("name", this.state.name);
    form_data.append("name_vi", this.state.name_vi);
    form_data.append("type", this.state.type);
    form_data.append("price", this.state.price);
    if (this.state.attachment)
      form_data.append("image", this.state.attachment);
    axios
      .post(baseUrl + "/rolling-item", form_data, {
        headers: this.generateHeaders()
      })
      .then(response => {
        alert("Create item successfully");
        let link = window.location.href.replace("create", response.data.data.id);
        window.location.href = link;
      }).catch(error => {
      alert(error.response.data.message)
    })
  };

  handleImageChange = e => {
    let file = URL.createObjectURL(e.target.files[0]);
    this.setState({
      preview: file,
      attachment: e.target.files[0]
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    return (
      <div className="form-group">
        <Fragment>

          <div>
            <div>Name:</div>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("name")}
            />
          </div>
          <div>
            <div>Name Vietnamese:</div>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("name_vi")}
            />
          </div>
          <div>
            <div>Price:</div>
            <input
              type="number"
              className="form-control"
              onChange={this.handleChange("price")}
            />
          </div>
          <div>
            <div>Type:</div>
            <select
              className="form-control"
              onChange={this.handleChange("type")}
            >
              <option value="money">Money</option>
              <option value="item">Item</option>
            </select>
          </div>
          {this.state.type == "item" && (
            <div className="attachment-photo">
              <img
                className="Photo"
                alt="attachment"
                src={this.state.preview ? this.state.preview : DefaultNoImage}
              />
              <div>
                <div className="ChangePhotoText">Update attachment</div>
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  onChange={this.handleImageChange}
                />
              </div>
            </div>
          )}
          <div className="action-button">
            <input
              type="button"
              className="accept-button"
              onClick={this.submit}
              value="Submit"
            />
          </div>
        </Fragment>
      </div>
    );
  }
}

export default connect(
  null,
  {}
)(RollingItemCustomInput);
