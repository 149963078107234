import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
// import "./ApproveButton.scss";
import "./bootstrap.css";
import DefaultNoImage from "./assets/default-attachment.png";

class EventWeekCustomInput extends Component {
    state = {
        id: 0,
        title: "",
        start: "",
        end: "",
        discount: 0,
        thumbnail: null,
        popup_thumbnail: null,
        thumbnail_en: null,
        popup_thumbnail_en: null,
        isActive: false,
        preview_thumbnail: "",
        preview_popup_thumbnail: "",
        preview_thumbnail_en: "",
        preview_popup_thumbnail_en: "",
        courseList: [],
        courseEvent: []
    };

    componentDidMount() {
        const {record} = this.props;
        if (record.id) {
            this.setState({
                id: record.id,
                title: record.title,
                start: record.start,
                end: record.end,
                discount: record.discount,
                preview_thumbnail: record.thumbnail,
                preview_popup_thumbnail: record.popup_thumbnail,
                preview_thumbnail_en: record.thumbnail_en,
                preview_popup_thumbnail_en: record.popup_thumbnail_en,
                isActive: record.is_active,
                courseEvent: record.courses
            });
        }

        this.getCourse();
    }

    handleChangeTeachingRow = (e, index, name) => {
        let val = e.target.value;
        this.setState(state => {
            let arr = state.courseEvent;
            arr[index][name] = val;
            state.courseEvent = arr;
            return state;
        })
    }

    createTeachingRow = () => {
        let row = {
            course_id: 0,
            total: 0,
            sold_number: 0,
            remain_display: 0
        };
        this.setState(state => {
            let arr = state.courseEvent;
            arr.push(row);
            state.courseEvent = arr;
            return state;
        })
    }

    getCourse = () => {
        let t_this = this;
        axios.get(baseUrl + "/event-week/course", {
            headers: this.generateHeaders()
        }).then(function (res){
            t_this.setState({courseList: res.data})
        })
    }

    generateHeaders = () => {
        const token = localStorage.getItem(tokenKey);
        return {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        };
    };

    submit = () => {
        let form_data = new FormData();
        form_data.append("title", this.state.title);
        form_data.append("start", this.state.start);
        form_data.append("end", this.state.end);
        form_data.append("discount", this.state.discount);
        form_data.append("is_active", (this.state.isActive) ? 1 : 0);
        form_data.append("course_event", JSON.stringify(this.state.courseEvent));
        if (this.state.thumbnail)
            form_data.append("thumbnail", this.state.thumbnail);
        if (this.state.popup_thumbnail)
            form_data.append("popup_thumbnail", this.state.popup_thumbnail);
        if (this.state.thumbnail_en)
            form_data.append("thumbnail_en", this.state.thumbnail_en);
        if (this.state.popup_thumbnail_en)
            form_data.append("popup_thumbnail_en", this.state.popup_thumbnail_en);
        let url = (this.state.id) ? "/event-week/update/" + this.state.id : "/event-week/create";
        axios.post(baseUrl + url, form_data, {
                headers: this.generateHeaders()
            })
            .then(response => {
                alert("Item submitted!");
                window.location.href = window.location.href.replace("create", response.data.data.id);
            }).catch(error => {
            alert(error.response.data.message)
        })
    };

    deleteResource(id) {
        if (!window.confirm("Are you sure to delete this item?")) return;
        axios.delete(baseUrl + "/event-week/" + id, {
            headers: this.generateHeaders()
        }).then(response => {
            alert("Delete successfully!");
            window.location.href = "/#/event-week";
        }).catch(error => {
            alert(error.response.data.message)
        })
    }

    handleImageChange = e => {
        let file = URL.createObjectURL(e.target.files[0]);
        let name = e.target.name;
        this.setState({
            ["preview_" + name]: file,
            [name]: e.target.files[0]
        });
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };

    render() {
        return (
            <div className="form-group">
                <Fragment>
                    <div>
                        <div>Title</div>
                        <input
                            type="text"
                            className="form-control"
                            onChange={this.handleChange("title")}
                            value={this.state.title}
                        />
                    </div>
                    <div>
                        <div>Start</div>
                        <input
                            type="text"
                            className="form-control"
                            onChange={this.handleChange("start")}
                            value={this.state.start}
                            placeholder="YYYY-MM-DD"
                        />
                    </div>
                    <div>
                        <div>End</div>
                        <input
                            type="text"
                            className="form-control"
                            onChange={this.handleChange("end")}
                            value={this.state.end}
                            placeholder="YYYY-MM-DD"
                        />
                    </div>
                    <div>
                        <div>Discount (%)</div>
                        <input
                            type="number"
                            className="form-control"
                            onChange={this.handleChange("discount")}
                            value={this.state.discount}
                        />
                    </div>
                    <div>
                        <label htmlFor="feature">Active
                            <input
                                id="feature"
                                type="checkbox"
                                style={{marginLeft: "15px"}}
                                onChange={event => this.setState({isActive: event.target.checked})}
                                value="1"
                                checked={this.state.isActive}
                            />
                        </label>
                    </div>

                    <div>
                        <div>Course</div>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <td>Course</td>
                                <td>Total</td>
                                <td>Sold</td>
                                <td>Remain display</td>
                                <td>
                                    <button type="button" onClick={() => this.createTeachingRow()}>Add row</button>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.courseEvent.map((item, index) => (
                                <tr key={index}>
                                    <td style={{padding: "10px"}}>
                                        <select value={item.course_id} onChange={event => this.handleChangeTeachingRow(event, index, "course_id")} className="form-control">
                                            <option value="0">Select course</option>
                                            {this.state.courseList.map((it, index) => (
                                                <option value={it.id} key={index}>{it.level} - {(it.is_english)? "English" : "Skill"}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td style={{padding: "10px"}}><input value={item.total} type="number" onChange={event => this.handleChangeTeachingRow(event, index, "total")} className="form-control"/></td>
                                    <td style={{padding: "10px"}}><input value={item.sold_number} type="number" onChange={event => this.handleChangeTeachingRow(event, index, "sold_number")} className="form-control"/></td>
                                    <td style={{padding: "10px"}}><input value={item.remain_display} type="number" onChange={event => this.handleChangeTeachingRow(event, index, "remain_display")} className="form-control"/></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="attachment-photo">
                        <img className="Photo" alt="attachment" src={this.state.preview_thumbnail ? this.state.preview_thumbnail : DefaultNoImage}/>
                        <div>
                            <div className="ChangePhotoText">Update thumbnail</div>
                            <input
                                type="file"
                                accept="image/*"
                                name="thumbnail"
                                onChange={this.handleImageChange}
                            />
                        </div>
                    </div>
                    <div className="attachment-photo">
                        <img className="Photo" alt="attachment" src={this.state.preview_popup_thumbnail ? this.state.preview_popup_thumbnail : DefaultNoImage}/>
                        <div>
                            <div className="ChangePhotoText">Update popup thumbnail</div>
                            <input
                                type="file"
                                accept="image/*"
                                name="popup_thumbnail"
                                onChange={this.handleImageChange}
                            />
                        </div>
                    </div>

                    <div className="attachment-photo">
                        <img className="Photo" alt="attachment" src={this.state.preview_thumbnail_en ? this.state.preview_thumbnail_en : DefaultNoImage}/>
                        <div>
                            <div className="ChangePhotoText">Update thumbnail (for English)</div>
                            <input
                                type="file"
                                accept="image/*"
                                name="thumbnail_en"
                                onChange={this.handleImageChange}
                            />
                        </div>
                    </div>
                    <div className="attachment-photo">
                        <img className="Photo" alt="attachment" src={this.state.preview_popup_thumbnail_en ? this.state.preview_popup_thumbnail_en : DefaultNoImage}/>
                        <div>
                            <div className="ChangePhotoText">Update popup thumbnail (for English)</div>
                            <input
                                type="file"
                                accept="image/*"
                                name="popup_thumbnail_en"
                                onChange={this.handleImageChange}
                            />
                        </div>
                    </div>

                    <div style={{marginTop: "15px"}}>
                        <button className="btn btn-primary" onClick={this.submit}>Submit</button>
                        {this.state.id > 0 && (
                            <button className="btn btn-danger" onClick={() => this.deleteResource(this.state.id)}
                                    style={{marginLeft: "20px"}}>Delete</button>
                        )}
                    </div>
                </Fragment>
            </div>
        )
            ;
    }
}

export default connect(
    null,
    {}
)(EventWeekCustomInput);