import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  ImageField,
  ArrayInput, SimpleFormIterator, LongTextInput
} from "react-admin";
import PresenterCustomInput from "./PresenterCustomInput";

const PresenterFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn/>
  </Filter>
);

export const PresenterList = props => (
  <List {...props} filters={<PresenterFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="level" label="Position - Level"/>
      <EditButton basePath="/presenter"/>
    </Datagrid>
  </List>
);

export const PresenterEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <TextInput source="name"/>
      <TextInput source="level" label="Position - Level"/>
      <LongTextInput source="description"/>
      <LongTextInput source="educate_process" label="Educate process (About me)"/>
      <ArrayInput source="teaching_process">
        <SimpleFormIterator>
          <TextInput source="start"/>
          <TextInput source="end"/>
          <TextInput source="location" label="Workplace"/>
          <LongTextInput source="description"/>
        </SimpleFormIterator>
      </ArrayInput>
      <ImageField source="avatar"/>
    </SimpleForm>
  </Edit>
);

export const PresenterCreate = props => (
  <Create {...props}>
    <PresenterCustomInput/>
  </Create>
);
