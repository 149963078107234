import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  TextField,
  EditButton,
  TextInput,
  SelectInput,
  Filter
} from "react-admin";
import CourseCustomInput from "./CourseCustomInput";

const CourseFilter = (props) => (
    <Filter {...props}>
      <TextInput source="name" label="Title" alwaysOn/>
      <SelectInput source="level" choices={[
        {id: "Beginner", name: "Beginner"},
        {id: "Intermediate", name: "Intermediate"},
        {id: "Advanced", name: "Advanced"},
        {id: "Expert", name: "Expert"},
        {id: "Master", name: "Master"}
      ]}/>
      <SelectInput source="type" choices={[
        {id: "english", name: "English"},
        {id: "skill", name: "Skill"}
      ]}/>
    </Filter>
);

const redirectChild = (id) => {
  window.location.href = "/#/list-course?parent=" + id;
};

const FullNameField = ({record = {}}) => <span style={{cursor: "pointer"}}
                                               onClick={redirectChild.bind(this, record.id)}>View</span>;
FullNameField.defaultProps = {label: 'List Course'};

export const CourseList = props => (
    <List {...props} filters={<CourseFilter/>} filter={{is_published: true}}>
      <Datagrid>
        <TextField source="id"/>
        <TextField source="title"/>
        <TextField source="title_vi" label="Vietnamese Title"/>
        <TextField source="description"/>
        <TextField source="description_vi" label="Description Vietnamese"/>
        <TextField source="level"/>
        <TextField source="price"/>
        <TextField source="type" label="Course Type" sortable={false}/>
        <EditButton basePath="/level-course"/>
        <FullNameField source="id" sortable={false}/>
      </Datagrid>
    </List>
);

export const CourseEdit = props => (
    <Edit {...props}>
      <CourseCustomInput/>
    </Edit>
);

export const CourseCreate = props => (
    <Create title="Create a Course" {...props}>
      <CourseCustomInput/>
    </Create>
);
