import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
import "./ApproveButton.scss";

class UserButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 0
        }
    }

    generateHeaders = () => {
        const token = localStorage.getItem(tokenKey);
        return {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        };
    };

    activate = () => {
        const {record} = this.props;
        let cf = window.confirm("Are you sure to active this user?");
        if (cf === true) {
            axios
                .post(baseUrl + "/users/active/" + record.id, null, {
                    headers: this.generateHeaders()
                })
                .then(response => {
                    window.alert("Activate successfully!");
                    window.location.reload();
                })
                .catch(error => {
                    window.alert(
                        "Activate failed, error:" + JSON.stringify(error.response)
                    );
                });
        }
    };

    // upgradeRankZeroManager = () => {
    //   const {record} = this.props;
    //   let cf = window.confirm("Are you sure to upgrade rank this user?");
    //   if (cf === true) {
    //     axios
    //       .post(
    //         baseUrl + "/users/up-rank/" + record.id,
    //         {up_rank: 0, price: 500},
    //         {
    //           headers: this.generateHeaders()
    //         }
    //       )
    //       .then(response => {
    //         window.confirm("Send reward successfully!") && window.location.reload();
    //       })
    //       .catch(error => {
    //         window.alert("Upgrade failed, error:" + JSON.stringify(error.response));
    //       });
    //   }
    // };
    // upgradeRankZeroDirector = () => {
    //   const {record} = this.props;
    //   let cf = window.confirm("Are you sure to send reward this user?");
    //   if (cf === true) {
    //     axios
    //       .post(
    //         baseUrl + "/users/up-rank/" + record.id,
    //         {up_rank: 0, price: 2000},
    //         {
    //           headers: this.generateHeaders()
    //         }
    //       )
    //       .then(response => {
    //         window.alert("Send reward successfully!") && window.location.reload();
    //       })
    //       .catch(error => {
    //         window.alert("Upgrade failed, error:" + JSON.stringify(error.response));
    //       });
    //   }
    // };
    //
    // upgradeRankOne = () => {
    //   const {record} = this.props;
    //   let cf = window.confirm("Are you sure to send reward this user?");
    //   if (cf === true) {
    //     axios
    //       .post(
    //         baseUrl + "/users/up-rank/" + record.id,
    //         {up_rank: 1},
    //         {
    //           headers: this.generateHeaders()
    //         }
    //       )
    //       .then(response => {
    //         window.alert("Upgrade successfully!") && window.location.reload();
    //       })
    //       .catch(error => {
    //         window.alert("Upgrade failed, error:" + JSON.stringify(error.response));
    //       });
    //   }
    // };
    //
    // downgradeRank = () => {
    //   const {record} = this.props;
    //   let cf = window.confirm("Are you sure to downgrade this user?");
    //   if (cf === true) {
    //     axios
    //       .post(
    //         baseUrl + "/users/down-rank/" + record.id,
    //         {},
    //         {
    //           headers: this.generateHeaders()
    //         }
    //       )
    //       .then(response => {
    //         window.alert("Downgrade successfully!") && window.location.reload();
    //       })
    //       .catch(error => {
    //         window.alert("Downgrade failed, error:" + JSON.stringify(error.response));
    //       });
    //   }
    // }

    lockAccount = (text) => {
        const {record} = this.props;
        let cf = window.confirm("Are you sure to " + text + " this user?");
        if (cf === true) {
            axios
                .post(
                    baseUrl + "/users/lock/" + record.id,
                    {},
                    {
                        headers: this.generateHeaders()
                    }
                )
                .then(response => {
                    window.alert(text + " successfully!");
                    window.location.reload();
                })
                .catch(error => {
                    window.alert(error.message);
                });
        }

    };

    banAccount = (text) => {
        const {record} = this.props;
        let cf = window.confirm("Are you sure to " + text + " this user?");
        if (cf === true) {
            axios
                .post(
                    baseUrl + "/users/terminate/" + record.id,
                    {},
                    {
                        headers: this.generateHeaders()
                    }
                )
                .then(response => {
                    window.alert(text + " successfully!");
                    window.location.reload();
                })
                .catch(error => {
                    window.alert(error.message);
                });
        }

    };

    licenseKidz = (text) => {
        const {record} = this.props;
        let cf = window.confirm("Are you sure " + text + " to this user?");
        if (cf === true) {
            axios
                .post(
                    baseUrl + "/users/license-kidz/" + record.id,
                    {},
                    {
                        headers: this.generateHeaders()
                    }
                )
                .then(response => {
                    let data = response.data
                    if (data.statusCode === 200) {
                        window.alert(text + " successfully!");
                        window.location.reload();
                    } else
                        window.alert(data.message);
                })
                .catch(error => {
                    window.alert(error.message);
                });
        }

    };

    depositAccount = (user_id) => {
        let amount = this.state.amount;
        if (amount <= 0) {
            alert("Please input amount!");
            return;
        }
        if (!window.confirm("Deposit to this account?")) return;
        axios
            .post(
                baseUrl + "/transfer/force",
                {
                    user_id: user_id,
                    amount: amount
                },
                {
                    headers: this.generateHeaders()
                }
            )
            .then(response => {
                window.alert("Deposit successfully!");
                window.location.reload();
            })
            .catch(error => {
                window.alert(error.message);
            });
    }

    render() {
        return (
            <Fragment>
                <div>
                    <div>Deposit:</div>
                    <input
                        type="number"
                        className="form-control"
                        onChange={e => this.setState({amount: e.target.value})}
                        value={this.state.amount}
                    />
                </div>
                <div className="action-button">
                    <div>
                        <input
                            className="accept-button"
                            type="button"
                            onClick={() => this.depositAccount(this.props.record.id)}
                            value="Deposit"
                        />
                    </div>
                    <div>
                        <input
                            className="accept-button"
                            type="button"
                            onClick={this.activate}
                            value="Activate"
                        />
                    </div>
                    <div>
                        <input
                            className="accept-button"
                            type="button"
                            onClick={() => this.lockAccount((this.props.record.is_lock === 1) ? "Unlock" : "Lock")}
                            value={(this.props.record.freeze === 1) ? "Unlock Account" : "Lock Account"}
                        />
                    </div>
                    <div>
                        <input
                            className="accept-button"
                            type="button"
                            onClick={() => this.banAccount((this.props.record.is_lock === 1) ? "Cancel terminate" : "Terminate")}
                            value={(this.props.record.is_lock === 1) ? "Cancel terminate Account" : "Terminate Account"}
                        />
                    </div>
                    <div>
                        <input
                            className="accept-button"
                            type="button"
                            onClick={() => this.licenseKidz((this.props.record.is_active_license_kidz === 1) ? "Cancel active Kidz license" : "Active Kidz license")}
                            value={(this.props.record.is_active_license_kidz === 1) ? "Cancel active Kidz license" : "Active Kidz license"}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default connect(null, {})(UserButtons);
