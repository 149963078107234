import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  ImageField
} from "react-admin";
import AudioCustomInput from "./AudioCustomInput";

const AudioFilter = (props) => (
  <Filter {...props}>
    <TextInput source="title" alwaysOn/>
    <TextInput source="book_name" alwaysOn/>
  </Filter>
);

export const AudioList = props => (
  <List {...props} filters={<AudioFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="name_vi"/>
      <TextField source="order" label="Order point" />
      <TextField source="book_name"/>
      <EditButton basePath="/audio"/>
    </Datagrid>
  </List>
);

export const AudioEdit = props => (
  <Edit {...props}>
    <AudioCustomInput/>
  </Edit>
);

export const AudioCreate = props => (
  <Create {...props}>
    <AudioCustomInput/>
  </Create>
);
