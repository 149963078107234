import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  FunctionField
} from "react-admin";

const RatioFilter = (props) => (
  <Filter {...props}>
    <TextInput source="key" label="Key" alwaysOn/>
  </Filter>
);

export const SettingList = props => (
  <List {...props} filters={<RatioFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="key"/>
      <TextField source="value"/>
      <TextField source="description"/>
      <EditButton basePath="/setting"/>
      {/*<FullNameField source="id"/>*/}
    </Datagrid>
  </List>
);

export const SettingEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <DisabledInput source="key"/>
      <TextInput source="value"/>
      <TextInput source="description"/>
    </SimpleForm>
  </Edit>
);

export const SettingCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="key"/>
      <TextInput source="value"/>
      <TextInput source="description"/>
    </SimpleForm>
  </Create>
);
