import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  ImageField,
  SelectInput,
  SelectField,
  NumberInput
} from "react-admin";
import SpecialOptionCustomInput from "./SpecialOptionCustomInput";

const SpecialOptionFilter = (props) => (
  <Filter {...props}>
    <TextInput source="rank" alwaysOn/>
    <TextInput source="title" alwaysOn/>
  </Filter>
);

export const SpecialOptionList = props => (
  <List {...props} filters={<SpecialOptionFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="title"/>
      <TextField source="content"/>
      <TextField source="rank"/>
      <EditButton basePath="/special-option"/>
    </Datagrid>
  </List>
);

export const SpecialOptionEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <SelectInput
        source="rank"
        choices={[
          { id: "member", name: "Member" },
          { id: "gold", name: "Gold" },
          { id: "platinum", name: "Platinum" }
        ]}
      />
      <TextInput source="title"/>
      <TextInput source="content"/>
      <ImageField source="icon" />
    </SimpleForm>
  </Edit>
);

export const SpecialOptionCreate = props => (
  <Create {...props}>
    <SpecialOptionCustomInput/>
  </Create>
);
