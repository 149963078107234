import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { baseUrl, tokenKey } from "./authProvider";
import "./ApproveButton.scss";
import DefaultNoImage from "./assets/default-attachment.png";

class ApproveButton extends Component {
  state = {
    preview: "",
    attachment: {},
    reason: ""
  };

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  accept = () => {
    const { record } = this.props;
    let cf = window.confirm("Are you sure to accept this order?");
    if (cf === true) {
      if (record.type === "Withdrawal" && this.state.preview === "") {
        window.alert("Need a confirmation picture");
        return;
      }

      let form_data = new FormData();

      form_data.append("attachment", this.state.attachment);

      axios
        .post(baseUrl + "/orders/accept/" + record.id, form_data, {
          headers: this.generateHeaders()
        })
        .then(response => {
          window.confirm("Accept successfully!") && window.location.reload();
        })
        .catch(error => {
          window.alert("Accept failed, error:" + JSON.stringify(error.response));
        });
    }
  };

  deny = () => {
    let cf = window.confirm("Are you sure to deny this order?");
    if (cf === true) {
      if (this.state.reason === "") {
        window.alert("Must have deny reason!");
        return;
      }
      const {record} = this.props;

      axios
        .post(
          baseUrl + "/orders/denied/" + record.id,
          {
            note: this.state.reason
          },
          {headers: this.generateHeaders()}
        )
        .then(response => {
          window.confirm("Deny successfully!") && window.location.reload();
        })
        .catch(error => {
          window.alert("Deny failed, error:" + JSON.stringify(error.response));
        });
    }
  };

  handleImageChange = e => {
    let file = URL.createObjectURL(e.target.files[0]);
    this.setState({
      preview: file,
      attachment: e.target.files[0]
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    return (
      <div>
        {this.props.record.status === "Waiting" && (
          <Fragment>
            <div className="attachment-photo">
              <img
                className="Photo"
                alt="attachment"
                src={this.state.preview ? this.state.preview : DefaultNoImage}
              />
              <div>
                <div className="ChangePhotoText">Update attachment</div>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  name="attachment"
                  onChange={this.handleImageChange}
                />
              </div>
            </div>
            <div>
              <div>Deny reason:</div>
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange("reason")}
                name="reason"
              />
            </div>
            <div className="action-button">
              <input
                type="button"
                className="accept-button"
                onClick={this.accept}
                value="Accept"
              />
              <input
                type="button"
                className="deny-button"
                onClick={this.deny}
                value="Deny"
              />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default connect(
  null,
  {}
)(ApproveButton);
