import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter
} from "react-admin";
import LessonCustomInput from "./LessonCustomInput";

const redirectChild = (id) => {
  window.location.href = "/#/attachments?parent=" + id;
};

const FullNameField = ({record = {}}) => <span style={{cursor: "pointer"}}
                                               onClick={redirectChild.bind(this, record.id)}>View</span>;
FullNameField.defaultProps = {label: 'Lessons'};

const LessonFilter = (props) => (
  <Filter {...props}>
    <TextInput source="parent" label="Part Parent ID" alwaysOn/>
    <TextInput source="name" label="Title" alwaysOn/>
  </Filter>
);

export const LessonList = props => (
  <List {...props} filters={<LessonFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="title"/>
      <TextField source="title_vi" label="Vietnamese Title"/>
      <TextField source="part_course" sortable={false}/>
      <TextField source="course_type" sortable={false}/>
      <TextField source="order"/>
      <TextField source="link_video"/>
      <TextField source="link_video_vi"/>
      <TextField source="link_file" label="Link PDF EN"/>
      <TextField source="link_file_vi" label="Link PDF VN"/>

      <EditButton basePath="/lesson-course"/>
    </Datagrid>
  </List>
);

export const LessonEdit = props => (
  <Edit {...props}>
    <LessonCustomInput/>
  </Edit>
);

export const LessonCreate = props => (
  <Create {...props}>
    <LessonCustomInput/>
  </Create>
);
