import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  SelectInput
} from "react-admin";
import CustomContractButton from "./CustomContractButton";

const FrontIDField = ({record = {}}) => <a href={record.front_id_card} target="_blank" style={{cursor: "pointer"}}>Open</a>;
const BackIDField = ({record = {}}) => <a href={record.back_id_card} target="_blank" style={{cursor: "pointer"}}>Open</a>;

const ContractFilter = (props) => (
  <Filter {...props}>
    <TextInput source="code" label="User code" alwaysOn/>
    <TextInput source="email" alwaysOn/>
    <TextInput source="id_card" />
    <SelectInput source="status" label="Contract Status" choices={[
      { id: 'waiting', name: 'Waiting' },
      { id: 'accepted', name: 'Accepted' },
      { id: 'denied', name: 'Denied' },
    ]} />
  </Filter>
);

export const ContractList = props => (
  <List {...props} filters={<ContractFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="email"/>
      <TextField source="code"/>
      <TextField source="id_card"/>
      <TextField source="tax_code"/>
      <TextField source="bank_name"/>
      <TextField source="bank_account" label="Bank account number"/>
      <TextField source="full_name"/>
      <FrontIDField source="front_id_card" />
      <BackIDField source="back_id_card" />
      {/*<TextField source="front_id_card" />*/}
      {/*<TextField source="back_id_card"/>*/}
      <TextField source="status"/>
      <EditButton basePath="/contract"/>
    </Datagrid>
  </List>
);

export const ContractEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <DisabledInput source="name"/>
      <DisabledInput source="email"/>
      <DisabledInput source="code"/>
      <DisabledInput source="phone"/>
      <DisabledInput source="id_card"/>
      <DisabledInput source="address"/>
      <DisabledInput source="tax_code"/>
      <DisabledInput source="bank_name"/>
      <DisabledInput source="bank_account" label="Bank account number"/>
      <DisabledInput source="full_name"/>
      <DisabledInput source="status"/>
      <CustomContractButton />
    </SimpleForm>
  </Edit>
);
