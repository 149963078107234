import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter
} from "react-admin";

const TestFilter = (props) => (
  <Filter {...props}>
    <TextInput source="level" alwaysOn/>
  </Filter>
);

export const TestList = props => (
  <List {...props} filters={<TestFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="level"/>
      <TextField source="lang"/>
      <EditButton basePath="/test"/>
    </Datagrid>
  </List>
);

export const TestEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <TextInput source="level"/>
      <TextInput source="lang"/>
      <TextInput source="max_question"/>
      <TextInput source="question_num"/>
      <TextInput source="max_customer_turn"/>
      <TextInput source="max_time"/>
    </SimpleForm>
  </Edit>
);

export const TestCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="level"/>
      <TextInput source="lang"/>
      <TextInput source="max_question"/>
      <TextInput source="question_num"/>
      <TextInput source="max_customer_turn"/>
      <TextInput source="max_time"/>
    </SimpleForm>
  </Create>
);
