import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  SelectInput
} from "react-admin";

const IntroduceHistoryFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" label="User name" alwaysOn/>
    <TextInput source="code" label="User code" alwaysOn/>
    <TextInput source="level" label="Course level" alwaysOn/>
  </Filter>
);

export const IntroduceHistoryList = props => (
  <List {...props} filters={<IntroduceHistoryFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id" label="#"/>
      <TextField source="created_at" label="Date"/>
      <TextField source="user_name"/>
      <TextField source="user_code"/>
      <TextField source="user_email"/>
      <TextField source="course_name"/>
      <TextField source="course_level"/>
      <TextField source="course_price"/>
      <TextField source="f1_name"/>
      <TextField source="f1_code"/>
      <TextField source="percent_f1"/>
      <TextField source="f1_receive"/>
      <TextField source="parent_f1_name"/>
      <TextField source="parent_f1_code"/>
      <TextField source="percent_f2" label="Percent for F2"/>
      <TextField source="f2_receive"/>
      <TextField source="commission" label="Profit Company"/>
      <EditButton basePath="/commission-history-affiliate"/>
    </Datagrid>
  </List>
);

export const IntroduceHistoryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id" label="#"/>
      <DisabledInput source="created_at" label="Date"/>
      <DisabledInput source="user_name"/>
      <DisabledInput source="user_code"/>
      <DisabledInput source="user_email"/>
      <DisabledInput source="course_name"/>
      <DisabledInput source="course_level"/>
      <DisabledInput source="course_price"/>
      <DisabledInput source="f1_name"/>
      <DisabledInput source="f1_code"/>
      <DisabledInput source="percent_f1"/>
      <DisabledInput source="f1_receive"/>
      <DisabledInput source="parent_f1_name"/>
      <DisabledInput source="parent_f1_code"/>
      <DisabledInput source="percent_f2" label="Percent for F2"/>
      <DisabledInput source="f2_receive"/>
      <DisabledInput source="commission" label="Profit Company"/>
    </SimpleForm>
  </Edit>
);
