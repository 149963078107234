import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
import "./ApproveButton.scss";
import DefaultNoImage from "./assets/default-attachment.png";

class CustomContractButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: ""
    }
  }

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  accept = (id) => {
    // const { record } = this.props;
    let cf = window.confirm("Are you sure to accept this contract?");
    if (cf === true) {
      axios
        .put(baseUrl + "/contract/" + id, {status: "accepted"}, {
          headers: this.generateHeaders()
        })
        .then(response => {
          window.confirm("Accept successfully!") && window.location.reload();
        })
        .catch(error => {
          window.alert("Accept failed, error:" + JSON.stringify(error.response));
        });
    }
  };

  deny = (id) => {
    if (!this.state.reason){
      alert("Please input deny reason!");
      return;
    }
    let cf = window.confirm("Are you sure to deny this contract?");
    if (cf === true) {
      axios
        .put(baseUrl + "/contract/" + id, {status: "denied", reason: this.state.reason}, {
          headers: this.generateHeaders()
        })
        .then(response => {
          window.confirm("Deny successfully!") && window.location.reload();
        })
        .catch(error => {
          window.alert("Deny failed, error:" + JSON.stringify(error.response));
        });
    }
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const {record} = this.props;
    return (
      <div>
        <div className="attachment-photo">
          <label>Front ID Card</label> <br/>
          <img
            className="Photo"
            alt="attachment"
            src={(record.front_id_card)? record.front_id_card : DefaultNoImage}
          />
        </div>
        <div className="attachment-photo">
          <label>Back ID Card</label> <br/>
          <img
            className="Photo"
            alt="attachment"
            src={(record.back_id_card)? record.back_id_card : DefaultNoImage}
          />
        </div>
        {record.status == "waiting" && (
          <Fragment>
            <div>
              <div>Deny reason:</div>
              <input
                type="text"
                className="form-control"
                onChange={e => this.setState({reason: e.target.value})}
                value={this.state.reason}
              />
            </div>
            <div className="action-button">
              <input
                type="button"
                className="accept-button"
                onClick={() => this.accept(record.id)}
                value="Accept"
              />
              <input
                type="button"
                className="deny-button"
                onClick={() => this.deny(record.id)}
                value="Deny"
              />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default connect(
  null,
  {}
)(CustomContractButton);
