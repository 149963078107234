import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  ImageField, SelectInput
} from "react-admin";
import OpinionCustomInput from "./OpinionCustomInput";

const OpinionFilter = (props) => (
  <Filter {...props}>
    <TextInput source="member_name" alwaysOn/>
  </Filter>
);

export const OpinionList = props => (
  <List {...props} filters={<OpinionFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="member_name"/>
      <TextField source="is_feature"/>
      <EditButton basePath="/opinion"/>
    </Datagrid>
  </List>
);

export const OpinionEdit = props => (
  <Edit {...props}>
    <OpinionCustomInput/>
  </Edit>
);

export const OpinionCreate = props => (
  <Create {...props}>
    <OpinionCustomInput/>
  </Create>
);
