import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
import "./bootstrap.css";
import "./ApproveButton.scss";
import DefaultNoImage from "./assets/default-attachment.png";
import JoditEditor from "jodit-react";

class OptimaKidzBookCustomInput extends Component {
  state = {
    id: 0,
    category_id: 0,
    new_category: "",
    name: "",
    name_vi: "",
    summary: "",
    summary_vi: "",
    type: "audio",
    order: 0,
    preview: "",
    attachment: null,
    categories: []
  };

  componentDidMount() {
    const {record} = this.props;
    if (record.id) {
      this.setState({
        id: record.id,
        category_id: record.category_id,
        name: record.name,
        name_vi: record.name_vi,
        summary: record.summary,
        summary_vi: record.summary_vi,
        order: record.order,
        preview: record.thumbnail,
        type: record.type
      });
    }

    this.getCategories();
  }

  getCategories = () => {
    let t_this = this;
    axios.get(baseUrl + "/kidz-all-category", {
      headers: this.generateHeaders()
    }).then(function (res) {
      t_this.setState({categories: res.data.data})
    })
  }

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  submit = () => {
    let form_data = new FormData();
    form_data.append("name", this.state.name);
    form_data.append("name_vi", this.state.name_vi);
    form_data.append("summary", this.state.summary);
    form_data.append("summary_vi", this.state.summary_vi);
    form_data.append("order", this.state.order);
    form_data.append("type", this.state.type);
    form_data.append("new_category", this.state.new_category);
    form_data.append("category_id", this.state.category_id);
    if (this.state.attachment)
      form_data.append("thumbnail", this.state.attachment);
    if (this.state.id) {
      axios.post(baseUrl + "/kidz-book/update/" + this.state.id, form_data, {
        headers: this.generateHeaders()
      }).then(response => {
        alert("Edit kidz book successfully");
        window.location.reload();
      }).catch(error => {
        console.log(error);
        alert("Error")
      })
      return;
    }
    axios.post(baseUrl + "/kidz-book", form_data, {
      headers: this.generateHeaders()
    }).then(response => {
      alert("Create kidz book successfully");
      window.location.href = window.location.href.replace("create", response.data.data.id);
    }).catch(error => {
      console.log(error);
      alert("Error")
    })
  };

  delete = () => {
    if (!window.confirm("Do you want to delete this kidz book?")) return;

    axios.delete(baseUrl + "/kidz-book/" + this.state.id, {
      headers: this.generateHeaders()
    }).then(response => {
      alert("Delete kidz book success!");
      window.location.href = window.location.href.replace(this.state.id, "");
    }).catch(error => {
      console.log(error);
      alert("Error")
    })
  }


  handleImageChange = e => {
    let file = URL.createObjectURL(e.target.files[0]);
    this.setState({
      preview: file,
      attachment: e.target.files[0]
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  deleteCategory() {
    console.log("category_id: " + this.state.category_id)
    if (this.state.category_id == 0) {
      alert("Please select category first!");
      return;
    }

    if (!window.confirm("Do you want to remove this category?")) return;

    let t_this = this;

    axios.delete(baseUrl + "/kidz-book-category/" + this.state.category_id, {
      headers: this.generateHeaders()
    }).then(function (res) {
      let data = res.data;
      if (data.error)
        alert(data.error.message);
      else {
        alert("Remove category successfully;");
        t_this.getCategories();
      }
    })
  }

  render() {
    return (
        <div className="form-group">
          <Fragment>
            <div>
              <div>Name:</div>
              <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange("name")}
                  value={this.state.name}
              />
            </div>
            <div>
              <div>Name (VN):</div>
              <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange("name_vi")}
                  value={this.state.name_vi}
              />
            </div>
            <div>
              <div>Summary:</div>
              <JoditEditor
                  value={this.state.summary}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={newContent => this.setState({summary: newContent})} // preferred to use only this option to update the content for performance reasons
              />
            </div>
            <div>
              <div>Summary (VN):</div>
              <JoditEditor
                  value={this.state.summary_vi}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={newContent => this.setState({summary_vi: newContent})} // preferred to use only this option to update the content for performance reasons
              />
            </div>
            <div>
              <div>Type:</div>
              <select
                  className="form-control"
                  onChange={this.handleChange("type")}
                  value={this.state.type}
              >
                <option value="audio">Audio</option>
                <option value="course">Course</option>
              </select>
            </div>
            <div>
              <div>Category:</div>
              <div className="input-group">
                <select
                    className="form-control"
                    onChange={this.handleChange("category_id")}
                    value={this.state.category_id}
                >
                  <option value="0">Select</option>
                  {this.state.categories.map((item, index) => (
                      <option key={index} value={item.id}>{item.name} - {item.type}</option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <div>Order point</div>
              <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange("order")}
                  value={this.state.order}
              />
            </div>
            <div className="attachment-photo">
              <img
                  className="Photo"
                  alt="attachment"
                  src={this.state.preview ? this.state.preview : DefaultNoImage}
              />
              <div>
                <div className="ChangePhotoText">Update attachment</div>
                <input
                    type="file"
                    accept="image/*"
                    name="image"
                    onChange={this.handleImageChange}
                />
              </div>

            </div>
            <div className="action-button">
              <input
                  type="button"
                  className="accept-button"
                  onClick={this.submit}
                  value="Submit"
              />
            </div>
            {this.state.id ? (
                <div className="action-button">
                  <input
                      type="button"
                      className="accept-button"
                      onClick={this.delete}
                      value="Delete"
                  />
                </div>
            ) : <></>}
          </Fragment>
        </div>
    );
  }
}

export default connect(
    null,
    {}
)(OptimaKidzBookCustomInput);