import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
// import "./ApproveButton.scss";
import "./bootstrap.css";
import DefaultNoImage from "./assets/default-attachment.png";

class OpinionCustomInput extends Component {
    state = {
        id: 0,
        memberName: "",
        videoUrl: "",
        thumbnail: "",
        order: 0,
        isFeature: false,
        preview: "",
    };

    componentDidMount() {
        const {record} = this.props;
        if (record.id) {
            this.setState({
                id: record.id,
                memberName: record.member_name,
                videoUrl: record.video_url,
                preview: record.thumbnail,
                order: record.order,
                isFeature: record.is_feature,
            });
        }
    }

    generateHeaders = () => {
        const token = localStorage.getItem(tokenKey);
        return {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        };
    };

    submit = () => {
        let form_data = new FormData();
        form_data.append("member_name", this.state.memberName);
        form_data.append("video_url", this.state.videoUrl);
        form_data.append("order", this.state.order);
        form_data.append("is_feature", (this.state.isFeature) ? 1 : 0);
        if (this.state.attachment)
            form_data.append("thumbnail", this.state.attachment);
        let url = (this.state.id) ? "/opinion/update/" + this.state.id : "/opinion/create";
        axios
            .post(baseUrl + url, form_data, {
                headers: this.generateHeaders()
            })
            .then(response => {
                alert("Item submitted!");
                window.location.href = window.location.href.replace("create", response.data.data.id);
            }).catch(error => {
            alert(error.response.data.message)
        })
    };

    deleteResource(id) {
        if (!window.confirm("Are you sure to delete this opinion?")) return;
        axios.delete(baseUrl + "/opinion/" + id, {
            headers: this.generateHeaders()
        }).then(response => {
            alert("Delete successfully!");
            window.location.href = "/#/opinion";
        }).catch(error => {
            alert(error.response.data.message)
        })
    }

    handleImageChange = e => {
        let file = URL.createObjectURL(e.target.files[0]);
        this.setState({
            preview: file,
            attachment: e.target.files[0]
        });
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };

    render() {
        return (
            <div className="form-group">
                <Fragment>

                    <div>
                        <div>Member Name:</div>
                        <input
                            type="text"
                            className="form-control"
                            onChange={this.handleChange("memberName")}
                            value={this.state.memberName}
                        />
                    </div>
                    <div>
                        <div>Video url</div>
                        <input
                            type="text"
                            className="form-control"
                            onChange={this.handleChange("videoUrl")}
                            value={this.state.videoUrl}
                        />
                    </div>
                    <div>
                        <div>Order</div>
                        <input
                            type="number"
                            className="form-control"
                            onChange={this.handleChange("order")}
                            value={this.state.order}
                        />
                    </div>
                    <div>
                        <label htmlFor="feature">Feature
                            <input
                                id="feature"
                                type="checkbox"
                                style={{marginLeft: "15px"}}
                                onChange={event => this.setState({isFeature: event.target.checked})}
                                value="1"
                                checked={this.state.isFeature}
                            />
                        </label>
                    </div>

                    <div className="attachment-photo">
                        <img
                            className="Photo"
                            alt="attachment"
                            src={this.state.preview ? this.state.preview : DefaultNoImage}
                        />
                        <div>
                            <div className="ChangePhotoText">Update attachment (recommended
                                size: {(this.state.type === "document") ? "260 x 334" : "352 x 198"}px)
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                name="image"
                                onChange={this.handleImageChange}
                            />
                        </div>
                    </div>

                    <div style={{marginTop: "15px"}}>
                        <button className="btn btn-primary" onClick={this.submit}>Submit</button>
                        {this.state.id > 0 && (
                            <button className="btn btn-danger" onClick={() => this.deleteResource(this.state.id)}
                                    style={{marginLeft: "20px"}}>Delete</button>
                        )}
                    </div>
                </Fragment>
            </div>
        )
            ;
    }
}

export default connect(
    null,
    {}
)(OpinionCustomInput);