import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Filter,
  ImageField, SelectInput
} from "react-admin";

const ActiveCodeFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" label="User name" alwaysOn/>
    <TextInput source="code" label="User code" alwaysOn/>
    <TextInput source="charge_code" label="Code" alwaysOn/>
  </Filter>
);

export const ActiveCodeList = props => (
  <List {...props} filters={<ActiveCodeFilter/>} filter={{is_published: true}}>
    <Datagrid>
      <TextField source="id"/>
      <TextField source="code"/>
      <TextField source="type_text" label="To Active"/>
      <TextField source="is_used" label="Used"/>
      <TextField source="used_time"/>
      <TextField source="user_name"/>
      <TextField source="user_code"/>
      <TextField source="user_email"/>
      <TextField source="created_at"/>
      <EditButton basePath="/active-code"/>
    </Datagrid>
  </List>
);

export const ActiveCodeEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      <DisabledInput source="code"/>
      <SelectInput source="active_type" choices={[
        {id: "course_skill_beginner",     name: "Course Skill Beginner"},
        {id: "course_skill_intermediate", name: "Course Skill Intermediate"},
        {id: "course_skill_advanced",     name: "Course Skill Advanced"},
        {id: "course_skill_expert",       name: "Course Skill Expert"},
        {id: "course_skill_master",       name: "Course Skill Master"},
        {id: "course_english_beginner",     name: "Course English Beginner"},
        {id: "course_english_intermediate", name: "Course English Intermediate"},
        {id: "course_english_advanced",     name: "Course English Advanced"},
        {id: "course_english_expert",       name: "Course English Expert"},
        {id: "course_english_master",       name: "Course English Master"},
        {id: "audio_books",       name: "Audio books"},
      ]}/>
      <DisabledInput source="is_used" label="Used"/>
      <DisabledInput source="used_time"/>
      <DisabledInput source="user_name"/>
    </SimpleForm>
  </Edit>
);

export const ActiveCodeCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput source="active_type" choices={[
        {id: "course_skill_beginner",     name: "Course Skill Beginner"},
        {id: "course_skill_intermediate", name: "Course Skill Intermediate"},
        {id: "course_skill_advanced",     name: "Course Skill Advanced"},
        {id: "course_skill_expert",       name: "Course Skill Expert"},
        {id: "course_skill_master",       name: "Course Skill Master"},
        {id: "course_english_beginner",     name: "Course English Beginner"},
        {id: "course_english_intermediate", name: "Course English Intermediate"},
        {id: "course_english_advanced",     name: "Course English Advanced"},
        {id: "course_english_expert",       name: "Course English Expert"},
        {id: "course_english_master",       name: "Course English Master"},
        {id: "audio_books",       name: "Audio books"},
      ]}/>
    </SimpleForm>
  </Create>
);
