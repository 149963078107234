import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
import "./ApproveButton.scss";
import DefaultNoImage from "./assets/default-attachment.png";

class AudioCustomInput extends Component {
  state = {
    id: 0,
    audio_book_id: 0,
    name: "",
    name_vi: "",
    duration: 0,
    order: 0,
    preview: "",
    preview_2: "",
    link_file: "",
    link_file_vi: "",
    audio_books: [],
    attachment: null,
    attachment_2: null,
    is_introduce: false
  };

  componentDidMount() {
    const {record} = this.props;
    if (record.id) {
      this.setState({
        id: record.id,
        audio_book_id: record.audio_book_id,
        name: record.name,
        name_vi: record.name_vi,
        duration: record.duration,
        order: record.order,
        preview: record.audio_file,
        preview_2: record.audio_file_vi,
        is_introduce: record.is_introduce,
        link_file: record.link_file,
        link_file_vi: record.link_file_vi,
      });
    }

    this.getCategories();
  }

  getCategories = () => {
    let t_this = this;
    axios.get(baseUrl + "/get-all-book").then(function (res) {
      t_this.setState({audio_books: res.data.data})
    })
  }

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  submit = () => {
    let form_data = new FormData();
    form_data.append("name", this.state.name);
    form_data.append("name_vi", this.state.name_vi);
    form_data.append("order", this.state.order);
    form_data.append("duration", this.state.duration);
    form_data.append("link_file", this.state.link_file);
    form_data.append("link_file_vi", this.state.link_file_vi);
    form_data.append("audio_book_id", this.state.audio_book_id);
    if (this.state.is_introduce)
      form_data.append("is_introduce", 1);
    if (this.state.attachment)
      form_data.append("audio_file", this.state.attachment);
    if (this.state.attachment_2)
      form_data.append("audio_file_vi", this.state.attachment_2);
    if (this.state.id) {
      axios.post(baseUrl + "/audio/update/" + this.state.id, form_data, {
        headers: this.generateHeaders()
      }).then(response => {
        alert("Edit audio successfully");
        // window.location.reload();
      }).catch(error => {
        console.log(error);
        alert("Error")
      })
      return;
    }
    axios.post(baseUrl + "/audio", form_data, {
      headers: this.generateHeaders()
    }).then(response => {
      alert("Create audio successfully");
      window.location.href = window.location.href.replace("create", response.data.data.id);
    }).catch(error => {
      console.log(error);
      alert("Error")
    })
  };

  update = () => {
    let payload = this.state;
    payload.audio_books = [];
    if (payload.is_introduce)
      payload.is_introduce = 'yes';
    else
      payload.is_introduce = 'no';

  };

  delete = () => {
    if (!window.confirm("Do you want to delete this audio?")) return;

    axios.delete(baseUrl + "/audio/" + this.state.id, {
      headers: this.generateHeaders()
    }).then(response => {
      alert("Delete audio success!");
      window.location.href = window.location.href.replace(this.state.id, "");
    }).catch(error => {
      console.log(error);
      alert("Error")
    })
  }


  // handleImageChange = e => {
  //     let file = URL.createObjectURL(e.target.files[0]);
  //     this.setState({
  //         preview: file,
  //         attachment: e.target.files[0]
  //     });
  // };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    return (
        <div className="form-group">
          <Fragment>
            <div>
              <div>Name:</div>
              <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange("name")}
                  value={this.state.name}
              />
            </div>
            <div>
              <div>Name (VN):</div>
              <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange("name_vi")}
                  value={this.state.name_vi}
              />
            </div>
            <div>
              <div>Audio Book ID:</div>
              <select
                  className="form-control"
                  onChange={this.handleChange("audio_book_id")}
                  value={this.state.audio_book_id}
              >
                <option value="0">Select</option>
                {this.state.audio_books.map((item, index) => (
                    <option key={index} value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
            <div>
              <div>Duration</div>
              <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange("duration")}
                  value={this.state.duration}
              />
            </div>
            <div>
              <input checked={this.state.is_introduce} type="checkbox" value="1" id="is_introduce"
                     onChange={e => this.setState({is_introduce: e.target.checked})}/>
              <label className="control-label" htmlFor="is_introduce" style={{marginLeft: "15px"}}>Is introduce
                ?</label>
            </div>
            <div>
              <div>Order point</div>
              <input
                  type="number"
                  className="form-control"
                  onChange={this.handleChange("order")}
                  value={this.state.order}
              />
            </div>
            <div className="attachment-photo">
              <div>
                <div className="ChangePhotoText"><b>Update audio</b></div>
                <input
                    type="file"
                    accept="audio/*"
                    name="image"
                    onChange={e => this.setState({attachment: e.target.files[0]})}
                />
              </div>
              {(this.state.preview) ? (
                  <div>
                    <div>Uploaded file: <a href={this.state.preview} target="_blank">{this.state.preview}</a></div>
                  </div>
              ) : (
                  <React.Fragment/>
              )}
            </div>
            <div className="attachment-photo">
              <div>
                <div className="ChangePhotoText"><b>Update audio (VN)</b></div>
                <input
                    type="file"
                    accept="audio/*"
                    name="image_2"
                    onChange={e => this.setState({attachment_2: e.target.files[0]})}
                />
              </div>
              {(this.state.preview) ? (
                  <div>
                    <div>Uploaded file: <a href={this.state.preview_2} target="_blank">{this.state.preview_2}</a></div>
                  </div>
              ) : (
                  <React.Fragment/>
              )}
            </div>

            <div>
              <div>Video link</div>
              <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange("link_file")}
                  value={this.state.link_file}
              />
            </div>
            <div>
              <div>Video link (VN)</div>
              <input
                  type="text"
                  className="form-control"
                  onChange={this.handleChange("link_file_vi")}
                  value={this.state.link_file_vi}
              />
            </div>
            <div className="action-button">
              <input
                  type="button"
                  className="accept-button"
                  onClick={this.submit}
                  value="Submit"
              />
            </div>
            {(this.state.id) ? (
                <div className="action-button">
                  <input
                      type="button"
                      className="accept-button"
                      onClick={this.delete}
                      value="Delete"
                  />
                </div>
            ) : <React.Fragment/>}
          </Fragment>
        </div>
    );
  }
}

export default connect(
    null,
    {}
)(AudioCustomInput);