import React, {Component} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
import "./ApproveButton.scss";

class PromotionHistoryButton extends Component {
  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  updateStatusPromotion = status => {
    let reason = "";
    if (status == "denied"){
      reason = document.getElementById("reason").value;
      if (!reason){
        alert("Please input the deny reason!");
        return;
      }
    }
    const {record} = this.props;
    let cf = window.confirm("Are you sure to " + status +" it?");
    if (cf === true) {
      axios
        .put(baseUrl + "/promotion-history/" + record.id, {
          status: status,
          note: reason
        }, {
          headers: this.generateHeaders()
        })
        .then(response => {
          window.confirm(status + " successfully!") && window.location.reload();
        })
        .catch(error => {
          window.alert(
            status + " failed, error:" + JSON.stringify(error.response)
          );
        });
    }
  }

  render() {
    const {record} = this.props;
    return (
      (record.status === "waiting") && (
        <React.Fragment>
          <div>
            <label>Reason</label> <br/>
            <input type="text" id="reason"/>
          </div>
          <div className="action-button">
            <div>
              <input
                className="accept-button"
                type="button"
                onClick={() => this.updateStatusPromotion("accepted")}
                value="Accept"
              />
            </div>
            <div>
              <input
                className="deny-button"
                type="button"
                onClick={() => this.updateStatusPromotion("denied")}
                value="Deny"
              />
            </div>
          </div>
        </React.Fragment>
      )
    );
  }
}

export default connect(null, {})(PromotionHistoryButton);
