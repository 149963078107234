import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {baseUrl, tokenKey} from "./authProvider";
// import "./ApproveButton.scss";
import "./bootstrap.css";
import DefaultNoImage from "./assets/default-attachment.png";

class ResourceCustomInput extends Component {
  state = {
    id: 0,
    name: "",
    type: "document",
    link: "",
    preview: "",
    category_id: 0,
    attachment: null,
    categories: [],
    newCategory: false,
    newCategoryValue: ""
  };

  componentDidMount() {
    const {record} = this.props;
    if (record.id) {
      this.setState({
        id: record.id,
        name: record.name,
        type: record.type,
        link: record.link,
        preview: record.thumbnail,
        category_id: record.category_id
      });
    }
    this.getCategoryList();
  }

  getCategoryList() {
    axios
      .get(baseUrl + "/guest/resource-category", {
        headers: this.generateHeaders()
      })
      .then(response => {
        this.setState({categories: response.data.data})
      }).catch(error => {
      alert(error.response.data.message)
    })
  }

  deleteCategory() {
    if (!this.state.category_id){
      alert("Please select category!");
      return;
    }
    axios
      .delete(baseUrl + "/resource-category/" + this.state.category_id, {
        headers: this.generateHeaders()
      })
      .then(response => {
        alert("Delete category successfully!");
        this.getCategoryList();
      }).catch(error => {
      alert(error.response.data.message)
    })
  }

  generateHeaders = () => {
    const token = localStorage.getItem(tokenKey);
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    };
  };

  submit = () => {
    let form_data = new FormData();
    form_data.append("name", this.state.name);
    form_data.append("type", this.state.type);
    if (this.state.type == "video") {
      form_data.append("new_category", this.state.newCategoryValue);
      form_data.append("category_id", this.state.category_id);
    }
    form_data.append("link", this.state.link);
    if (this.state.attachment)
      form_data.append("thumbnail", this.state.attachment);
    let url = (this.state.id) ? "/resource/update/" + this.state.id : "/resource/create";
    axios
      .post(baseUrl + url, form_data, {
        headers: this.generateHeaders()
      })
      .then(response => {
        alert("Item submitted!");
        window.location.href = window.location.href.replace("create", response.data.data.id);
      }).catch(error => {
      alert(error.response.data.message)
    })
  };

  deleteResource(id) {
    if (!window.confirm("Are you sure to delete this resource?")) return;
    axios.delete(baseUrl + "/resource/" + id, {
      headers: this.generateHeaders()
    }).then(response => {
      alert("Delete successfully!");
      window.location.href = "/#/resource";
    }).catch(error => {
      alert(error.response.data.message)
    })
  }

  handleImageChange = e => {
    let file = URL.createObjectURL(e.target.files[0]);
    this.setState({
      preview: file,
      attachment: e.target.files[0]
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const {categories} = this.state;
    return (
      <div className="form-group">
        <Fragment>

          <div>
            <div>Name:</div>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("name")}
              value={this.state.name}
            />
          </div>
          <div>
            <div>Type:</div>
            <select
              className="form-control"
              onChange={this.handleChange("type")}
              style={{height: "auto"}}
              value={this.state.type}
            >
              <option value="document">Document</option>
              <option value="video">Video</option>
            </select>
          </div>
          <div>
            <div>{(this.state.type === "document") ? "Document download link" : "Video link"}</div>
            <input
              type="text"
              className="form-control"
              onChange={this.handleChange("link")}
              value={this.state.link}
            />
          </div>
          {(this.state.type === "video") && (
            <div>
              <div>Video Category</div>
              <div className="row">
                <div className="col-6">
                  <select className="form-control" onChange={this.handleChange("category_id")} style={{height: "auto", position: "relative"}}
                          value={this.state.category_id}>
                    <option value="0">Select</option>
                    {categories.map((item, index) => (
                      <option key={index} value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-6">
                  {(this.state.newCategory) ? (
                    <input type="text" className="form-control" placeholder="Please input new category name"
                           value={this.state.newCategoryValue} onChange={this.handleChange("newCategoryValue")}/>
                  ) : (
                    <React.Fragment>
                      <button className="btn btn-info" onClick={() => this.deleteCategory()} style={{marginRight: "15px"}}>Delete selected category</button>
                      <button className="btn btn-primary" onClick={() => this.setState({newCategory: true})}>Create new category</button>
                    </React.Fragment>
                  )}
                </div>
              </div>

            </div>
          )}


          <div className="attachment-photo">
            <img
              className="Photo"
              alt="attachment"
              src={this.state.preview ? this.state.preview : DefaultNoImage}
            />
            <div>
              <div className="ChangePhotoText">Update attachment (recommended
                size: {(this.state.type === "document") ? "260 x 334" : "352 x 198"}px)
              </div>
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={this.handleImageChange}
              />
            </div>
          </div>

          <div style={{marginTop: "15px"}}>
            <button className="btn btn-primary" onClick={this.submit}>Submit</button>
            {this.state.id > 0 && (
              <button className="btn btn-danger" onClick={() => this.deleteResource(this.state.id)} style={{marginLeft: "20px"}}>Delete</button>
            )}
          </div>
        </Fragment>
      </div>
    );
  }
}

export default connect(
  null,
  {}
)(ResourceCustomInput);